import { FormattedMessage } from "react-intl";

const UserNotificationsList = ({ entities, handleReadNotification }) => {
    return (
        <>
            {entities.map((notification) => (
                <a
                    key={notification.id}
                    href="#"
                    onClick={() => handleReadNotification(notification.notification_id)}
                    className={`mb-2 navi-item ${!notification.read ? 'bg-light-warning' : ''}`}
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}
                >
                    <div className="navi-link">
                    <div className="navi-icon mr-2">
                        <i className="flaticon2-information text-info"></i>
                    </div>
                    <div className="navi-text">
                        <div className="font-weight-bolder">
                            {notification.title ? notification.title : <FormattedMessage id="PAGE.ALERTS.MANAGEMENT.EDIT.TYPE.NOTIFICATION" />}
                        </div>
                        <div className="text-muted">
                            {new Date(notification.start_date).toLocaleDateString('pt-BR')}
                        </div>
                    </div>
                    </div>
                </a>
            ))}
        </>
    )
};

export default UserNotificationsList;