import React, { useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import { ContentRoute } from "../../_metronic/layout";

export const Can = (props) => {
  const { roles } = useSelector((state) => state.auth, shallowEqual);
  const checkRules = useCallback((rules, action) => {
    return rules?.includes(action);
  }, []);

  return checkRules(roles, props.perform) ? props.yes() : props.no();
};

export const CanRoute = ({
  perform,
  path = "/",
  component: Component,
  exact,
}) => {
  return (
    <Can
      perform={perform}
      yes={() => {
        return <ContentRoute path={path} component={Component} exact={exact} />;
      }}
      no={() => {
        return <Redirect to="/home" />;
      }}
    />
  );
};

export const CanLink = ({ perform, link = "/", label }) => {
  return (
    <Can
      perform={perform}
      yes={() => <Link to={link}>{label}</Link>}
      no={() => null}
    />
  );
};

export const CanNavLink = ({ perform, children }) => {
  return <Can perform={perform} yes={() => children} no={() => null} />;
};
