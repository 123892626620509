import * as requestFromServer from "./bannersCrud";
import { bannersSlice, callTypes } from "./bannersSlice";

const { actions } = bannersSlice;

export const getBanners = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getBanners();
    dispatch(
      actions.bannersFetched({
        banners: response.data.banners,
      })
    );
  } catch (error) {
    error.clientMessage = "Can't get banners";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};
export const getPublicBanners = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getPublicBanners();
    dispatch(
      actions.getPublicBanners({
        banners: response.data,
      })
    );
  } catch (error) {
    error.clientMessage = "Can't get banners";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const getBanner = (id) => async (dispatch) => {
  if (!id) {
    return;
  }
  dispatch(actions.startCall({ callType: callTypes.list }));

  try {
    const response = await requestFromServer.getBanner(id);
    dispatch(actions.getBanner({ banner: response.data }));
  } catch (error) {
    error.clientMessage = "Can't get banner";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const createBanner = (bannerForCreation) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.createBanner(bannerForCreation);
    dispatch(actions.bannerCreated());
  } catch (error) {
    error.clientMessage = "Can't create banner";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};

export const updateBanner = (id, banner) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.updateBanner(id, banner);
    dispatch(actions.bannerUpdated());
  } catch (error) {
    error.clientMessage = "Can't update banner";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const deleteBanner = (id) => async (dispatch) => {
  if (!id) {
    return;
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.deleteBanner(id);
    dispatch(actions.bannerDeleted());
  } catch (error) {
    error.clientMessage = "Can't delete banner";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};

export const changeVisibility = (id) => async (dispatch) => {
  if (!id) {
    return;
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.changeVisibility(id);
    dispatch(actions.changeVisibility());
  } catch (error) {
    error.clientMessage = "Can't change visibility";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};

export const reorder = (positions) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.reorder(positions);
    dispatch(actions.reorder());
  } catch (error) {
    error.clientMessage = "Can't reorder banners";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};

export const removeBannerById = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    dispatch(actions.remove(id));
  } catch (error) {
    error.clientMessage = "Can't remove this banner";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
