import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { SearchResult } from "./SearchResult";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import api from "../../../../../../app/services/api";
import { injectIntl } from "react-intl";

function SearchDropdown({ intl }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [searchFilter, setSearchFilter] = useState({
    filter: {},
    searchText: "",
    pageNumber: 0,
    pageSize: 20,
    sortOrder: "ASC",
    sortField: "knowledge.id",
    totalCount: -1,
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchValue);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const resetSearch = () => {
    setData([]);
    setLoading(false);
    setSearchFilter({
      filter: {},
      searchText: "",
      pageNumber: 0,
      pageSize: 20,
      sortOrder: "ASC",
      sortField: "knowledge.id",
      totalCount: -1,
    });
  };

  const handleSearch = (inputValue) => {
    const searchFilter = {
      filter: {},
      searchText: inputValue,
      pageNumber: 0,
      pageSize: 20,
      sortOrder: "ASC",
      sortField: "knowledge.id",
      totalCount: -1,
    };

    setSearchFilter(searchFilter);

    if (searchFilter.searchText.length > 2) {
      findData(searchFilter);
    }
  };

  const showMore = () => {
    const newFilter = {
      filter: searchFilter.filter,
      searchText: searchFilter.searchText,
      pageNumber: searchFilter.pageNumber + 1,
      pageSize: 20,
      sortOrder: searchFilter.sortOrder,
      sortField: searchFilter.sortField,
      totalCount: searchFilter.totalCount,
    };
    setSearchFilter(newFilter);

    appendData(newFilter);
  };

  const findData = (filter) => {
    setLoading(true);
    api
      .post("knowledge/find", { ...filter })
      .then((response) => {
        setLoading(false);

        setSearchFilter((prevState) => ({
          ...prevState,
          totalCount: parseInt(response.data.totalRecords),
        }));

        setData(response.data.items);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  const appendData = (searchFilter) => {
    setLoading(true);
    api
      .post("knowledge/find", { ...searchFilter })
      .then((response) => {
        setLoading(false);

        setSearchFilter((prevState) => ({
          ...prevState,
          totalCount: parseInt(response.data.totalRecords),
        }));

        setData((prev) => [...prev, ...response.data.items]);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  const clear = () => {
    setSearchValue("");
    setData([]);
  };

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
          <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", { "quick-search-has-result": data && data.length })}
            >
              <form className="quick-search-form" style={{background: "#e4e6ef", padding: "0 25px", borderRadius: "20px" }}>
                <div className="input-group">
                  <div className={`input-group-prepend`}>
                    <span className="input-group-text">
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                        />
                      </span>
                    </span>
                  </div>
                  <input
                    type="text"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    autoFocus={true}
                    placeholder={intl.formatMessage({
                      id: "GENERAL_SEARCH.INPUT.PLACEHOLDER",
                    })}
                    value={searchValue}
                    onChange={(event) => {
                      resetSearch();
                      setSearchValue(event.target.value);
                    }}
                    className="form-control"
                  />

                  <div
                    className={`input-group-append ${
                      loading ? "spinner spinner-sm spinner-primary" : ""
                    }")}`}
                  >
                    <span className="input-group-text">
                      <i
                        style={{
                          display:
                            loading && searchValue && searchValue.length > 0
                              ? "none"
                              : "flex",
                        }}
                        onClick={clear}
                        className="quick-search-close ki ki-close icon-sm text-muted"
                      />
                    </span>
                  </div>
                </div>
              </form>
              <SearchResult
                data={data}
                showMore={showMore}
                searchFilter={searchFilter}
              />
            </div>
      )}
    </>
  );
}
export default injectIntl(SearchDropdown);
