import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const { REACT_APP_BASE_URL } = process.env;

const LatestNewsletters = ({ intl }) => {
  const { current } = useSelector(
    (state) => ({
      current: state.home,
    }),
    shallowEqual
  );

  const { pickUpRecentNewsletter } = current;

  return (
    <>
      <div className="card card-custom gutter-b">
        <div
          className="card-header border-0 pt-5 pb-0"
          style={{ minHeight: 0 }}
        >
          <span
            className="font-weight-bolder text-dark"
            style={{ fontSize: "1.275rem" }}
          >
            <FormattedMessage id="PAGE.HOME.LATEST_NEWSLETTER" />
          </span>
          <span className="font-weight-bolder" style={{ fontSize: "1rem" }}>
            <Link
              to="/knowledge-base/newsletter"
              className="text-muted text-hover-primary"
            >
              <FormattedMessage id="PAGE.HOME.LATEST_NEWSLETTER_MORE" />
            </Link>
          </span>
        </div>
        <div className="card-body d-flex" style={{ gap: 15, overflow: "auto" }}>
          {pickUpRecentNewsletter.length > 0 ? (
            pickUpRecentNewsletter.map((newsletter) => (
              <>
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    flex: 1,
                    overflow: "auto",
                    minWidth: 200,
                  }}
                >
                  <div
                    className="w-100 bg-light"
                    style={{
                      height: 200,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: newsletter.attachment_name
                        ? "cover"
                        : "100px",
                      backgroundPosition: "center",
                      backgroundImage: `url(${REACT_APP_BASE_URL}/knowledge/${
                        newsletter.attachment_name
                      }), url(${toAbsoluteUrl(
                        "media/svg/icons/Thomson/Globes-13_2-color.svg"
                      )}`,
                    }}
                  ></div>

                  <div
                    className="d-flex align-items-center p-5"
                    style={{
                      flexWrap: "nowrap",
                      gap: 15,
                    }}
                  >
                    {/* <div className="symbol symbol-60 symbol-light m-auto">
                      <span
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${REACT_APP_BASE_URL}/knowledge/${
                            newsletter.icon
                          }), url(${toAbsoluteUrl(
                            `media/svg/icons/Thomson/Globes-13_2-color.svg`
                          )}`,
                        }}
                      ></span>
                    </div> */}
                    <div
                      style={{ overflow: "auto" }}
                      className="d-flex flex-column flex-grow-1 font-weight-bolder"
                    >
                      <a
                        href={`/knowledge-base/view/${newsletter.id}`}
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {newsletter.title}
                      </a>

                      {/* <span className="text-muted">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: newsletter.description.replace(
                              "img",
                              "span"
                            ),
                          }}
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 4,
                            WebkitBoxOrient: "vertical",
                          }}
                        />
                      </span> */}
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <span className="text-muted">
              <FormattedMessage
                id={"PAGE.KNOWLEDGE.NEWSLETTER.MESSAGE.NO_RECORDS"}
              />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default injectIntl(LatestNewsletters);
