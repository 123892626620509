import React from "react";

import { BookmarksResult } from "./BookmarksResult";
import { BookmarkUIProvider } from "./BookmarkUIContext";

import BookmarkFilter from "./BookmarkFilter/index";

const BookmarkContentCard = () => {
  return (
    <BookmarkUIProvider>
      <BookmarkFilter />
      <div className="pt-5 pb-5">
        <BookmarksResult />
      </div>
    </BookmarkUIProvider>
  );
};

export default BookmarkContentCard;
