
export default function setupAxios(axios, store) {
  	axios.interceptors.request.use(
    	(config) => {
			const {
				auth: { authToken },
			} = store.getState();
      		if (authToken) {
        		config.headers.Authorization = `Bearer ${authToken}`;
      		}

      		return config;
    	},
		(err) => Promise.reject(err)
	  );
	  
	axios.interceptors.response.use(
		(response) => {
			return response
		},
		(err) => {
			const { status } = err.response.status;
			const { error } = err.response.data;

			if(error?.includes('JWT')) {
				localStorage.clear();
				window.location.reload();
			}

			return Promise.reject(err)
		}
	);
}
