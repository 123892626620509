import React from "react";
import { defineMessage, injectIntl } from "react-intl";

const PaginationTotal = ({ intl, ...props }) => {
  const { pageStartIndex, sizePerPage, dataSize } = props;

  const PaginationsTotalMessage = () => {
    const message = defineMessage({
      id: "REACT_BOOTSTRAP_TABLE.PAGINATION_TOTAL",
      defaultMessage:
        "Showing rows {pageStartIndex} to {sizePerPage} of {dataSize}",
    });

    return intl.formatMessage(message, {
      pageStartIndex: pageStartIndex,
      sizePerPage: sizePerPage,
      dataSize: dataSize,
    });
  };

  return (
    <>
      <PaginationsTotalMessage />
    </>
  );
};

export default injectIntl(PaginationTotal);
