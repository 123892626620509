import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import * as auth from "../app/modules/Auth/_redux/authRedux";

import { courseSlice } from "../app/modules/Learning/_redux/courses/slice";

// demos
import { alertsSlice } from "../app/modules/Alerts/_redux/alerts/alertsSlice";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { contentsSlice } from "../app/modules/KnowledgeBase/_redux/contents/contentsSlice";
import { faqsSlice } from "../app/modules/KnowledgeBase/_redux/faqs/faqsSlice";
import { linksSlice } from "../app/modules/KnowledgeBase/_redux/links/linksSlice";
import { manualsSlice } from "../app/modules/KnowledgeBase/_redux/manuals/manualsSlice";
import { newsSlice } from "../app/modules/KnowledgeBase/_redux/news/newsSlice";
import { newsletterSlice } from "../app/modules/KnowledgeBase/_redux/newsletter/newsletterSlice";
import { videosSlice } from "../app/modules/KnowledgeBase/_redux/videos/videosSlice";
import { viewsSlice } from "../app/modules/KnowledgeBase/_redux/views/viewsSlice";
import { certificateSlice } from "../app/modules/Learning/_redux/certificate/certificateSlice";
import { coursesManagementSlice } from "../app/modules/Learning/_redux/coursesManagement/courseManagementSlice";
import { testsSlice } from "../app/modules/Learning/_redux/tests/testsSlice";
import { assetsSlice } from "../app/modules/System/_redux/assets/assetsSlice";
import { bannersSlice } from "../app/modules/System/_redux/banners/bannersSlice";
import { usersSlice } from "../app/modules/System/_redux/users/usersSlice";
import { homeSlice } from "../app/pages/_redux/home/homeSlice";

import { settingsSlice } from "../app/modules/System/_redux/settings/settingsSlice";
import { templatesSlice } from "../app/modules/System/_redux/templates/templatesSlice";
import { accomplishmentSlice } from "../app/pages/_redux/accomplishments/accomplishmentsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,

  courses: courseSlice.reducer,

  // demos
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,

  alerts: alertsSlice.reducer,
  contents: contentsSlice.reducer,
  faqs: faqsSlice.reducer,
  links: linksSlice.reducer,
  manuals: manualsSlice.reducer,
  news: newsSlice.reducer,
  newsletter: newsletterSlice.reducer,
  videos: videosSlice.reducer,
  assets: assetsSlice.reducer,
  banner: bannersSlice.reducer,
  users: usersSlice.reducer,
  settings: settingsSlice.reducer,
  tests: testsSlice.reducer,
  certificate: certificateSlice.reducer,
  home: homeSlice.reducer,
  views: viewsSlice.reducer,
  coursesManagement: coursesManagementSlice.reducer,
  accomplishment: accomplishmentSlice.reducer,
  templates: templatesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
