import { createSlice } from "@reduxjs/toolkit";

const initialViewsState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  currentKnowledge: undefined,
  bookmark: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const viewsSlice = createSlice({
  name: "views",
  initialState: initialViewsState,
  reducers: {
    reset: () => initialViewsState,
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    knowledgeFetched: (state, action) => {
      const { currentKnowledge } = action.payload;
      state.currentKnowledge = currentKnowledge;
      state.listLoading = false;
      state.error = null;
    },
    knowledgeEvaluated: (state, action) => {
      state.currentKnowledge.evaluation = true;
      state.actionsLoading = false;
      state.error = null;
    },
    bookmarkPost: (state, action) => {
      const { bookmark } = action.payload;
      state.currentKnowledge.bookmark = bookmark.id;
      state.actionsLoading = false;
      state.error = null;
    },
    bookmarkDeleted: (state, action) => {
      state.currentKnowledge.bookmark = null;
      state.actionsLoading = false;
      state.error = null;
    },
    sharedKnowledgeByEmail: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
  },
});
