/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../../../_helpers";

const perfectScrollbarOptions = {
  wheelSpeed: 0.5,
  wheelPropagation: false,
};

export function SearchResult({ data, showMore, searchFilter }) {
  const { REACT_APP_BASE_URL } = process.env;

  if (data.length === 0 && searchFilter.totalCount === 0) {
    return (
      <div
        style={{
          maxHeight: "325px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          background: "#ffffff",
          display: "block",
        }}
        className="quick-search-wrapper scroll ps ps--active-y p-5"
      >
        <div className="quick-search-result">
          <div className="text-muted ">
            <FormattedMessage id="GENERAL_SEARCH.EMPTY_DATA.MESSAGE" />
          </div>
        </div>
      </div>
    );
  }

  const getKeyTranslation = (type) => {
    switch (type) {
      case "video": {
        return "GENERAL_SEARCH.TYPE.VIDEO";
      }
      case "faq": {
        return "GENERAL_SEARCH.TYPE.FAQ";
      }
      case "link": {
        return "GENERAL_SEARCH.TYPE.LINK";
      }
      case "news": {
        return "GENERAL_SEARCH.TYPE.NEWS";
      }
      case "manual": {
        return "GENERAL_SEARCH.TYPE.MANUAL";
      }

      default: {
        return "GENERAL_SEARCH.TYPE.COURSES";
      }
    }
  };

  const formattedData = () => {
    const formattedDataList = [];

    (data || []).forEach((item) => {
      const indexFormatted = formattedDataList.findIndex(
        (data) => data.type === item.knowledge_type
      );

      if (indexFormatted !== -1) {
        formattedDataList[indexFormatted].items.push(item);
      } else {
        formattedDataList.push({
          type: item.knowledge_type,
          items: [item],
        });
      }
    });

    return formattedDataList;
  };

  const returnIcon = (icon, type) => {
    if (!type) {
      return <img src={`${REACT_APP_BASE_URL}/course/${icon}`} alt="" />;
    } else {
      let onErrorImage;

      switch (type) {
        case "video":
          onErrorImage = toAbsoluteUrl("/media/svg/files/mp4.svg");
          break;
        case "faq":
          onErrorImage = toAbsoluteUrl("/media/svg/files/doc.svg");
          break;
        case "link":
          onErrorImage = toAbsoluteUrl("/media/svg/files/file.svg");
          break;
        case "news":
          onErrorImage = toAbsoluteUrl("/media/svg/files/file.svg");
          break;
        case "manual":
          onErrorImage = toAbsoluteUrl("/media/svg/files/doc.svg");
          break;
        default:
          onErrorImage = toAbsoluteUrl("/media/svg/files/file.svg");
          break;
      }

      return (
        <img
          src={`${REACT_APP_BASE_URL}/knowledge/${icon}`}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = onErrorImage;
          }}
        />
      );
    }
  };

  const returnDefaultIcon = (type) => {
    switch (type) {
      case "video": {
        return <SVG src={toAbsoluteUrl("/media/svg/files/mp4.svg")}></SVG>;
      }
      case "faq": {
        return <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}></SVG>;
      }
      case "link": {
        return <SVG src={toAbsoluteUrl("/media/svg/files/file.svg")}></SVG>;
      }
      case "news": {
        return <SVG src={toAbsoluteUrl("/media/svg/files/file.svg")}></SVG>;
      }
      case "manual": {
        return <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}></SVG>;
      }

      default: {
        return <SVG src={toAbsoluteUrl("/media/svg/files/file.svg")}></SVG>;
      }
    }
  };

  const remainingData = searchFilter.totalCount - data.length ?? 0;

  return (
    <div
      style={{
        maxHeight: "325px",
        maxWidth: "500px",
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        background: "#ffffff",
      }}
      className="quick-search-wrapper scroll ps ps--active-y p-5"
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: "325px", position: "relative" }}
      >
        <div className="quick-search-result">
          {formattedData().map((groupItem) => {
            return (
              <div key={groupItem.type}>
                <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                  <FormattedMessage id={getKeyTranslation(groupItem.type)} />
                </div>
                <div className="mb-10">
                  {groupItem.items.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="d-flex align-items-center flex-grow-1 mb-2"
                      >
                        <div className="symbol symbol-45 bg-transparent flex-shrink-0">
                          {item.icon
                            ? returnIcon(item.icon, groupItem.type)
                            : returnDefaultIcon(groupItem.type)}
                        </div>
                        <div className="d-flex flex-column ml-3 mt-2 mb-2">
                          <a
                            href={
                              groupItem.type
                                ? `/knowledge-base/view/${item.id}`
                                : `/learning/mycourses/${item.id}/view/class`
                            }
                            className="font-weight-bold text-dark text-hover-primary"
                          >
                            {item.title || item.name}
                          </a>
                          <span className="font-size-sm font-weight-bold text-muted">
                            {item.keywords?.length > 70
                              ? item.keywords.substring(0, 70).concat("...")
                              : item.keywords}
                          </span>

                          <span className="d-flex">
                            {item.products?.map(
                              (product, index) =>
                                index <= 3 && (
                                  <small className="badge bg-primary text-nowrap text-truncate text-white p-1 px-2 mr-1">
                                    {product.name}
                                  </small>
                                )
                            )}
                            {item.products?.length >= 3 && (
                              <span className="text-primary">...</span>
                            )}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        {remainingData > 0 && (
          <div className="text-center">
            <Button
              type="button"
              onClick={showMore}
              variant="none"
              className="p-0 m-0"
            >
              <span className="font-weight-bolder text-primary">
                <FormattedMessage
                  id="GENERAL_SEARCH.SHOW_MORE_DATA"
                  defaultMessage="Show more({remainingData})"
                  values={{
                    remainingData: remainingData,
                  }}
                />
              </span>
            </Button>
          </div>
        )}
      </PerfectScrollbar>
    </div>
  );
}
