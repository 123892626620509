import { createSlice } from '@reduxjs/toolkit';

const initialAlertsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    alertForEdit: null,
    entities: null,
    products: [],
    roles: [],
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState: initialAlertsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if(action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if(action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        alertFetched: (state, action) => {
            const { alertForEdit } = action.payload;
            if(alertForEdit) {
                alertForEdit.visibility = alertForEdit.is_public === true ? "0" : "1";
                alertForEdit.products = alertForEdit.products.map((product) => (
                    { value: product.id, label: product.name }
                ));
                alertForEdit.roles = alertForEdit.roles.map((role) => (
                    { value: role.id, label: role.name }
                ));

                if(alertForEdit.type === "WARNING") {
                    alertForEdit.alert_type = "0";
                } else {
                    alertForEdit.alert_type = "1";
                }

                if(alertForEdit.priority === "LOW") {
                    alertForEdit.alert_level = "0";
                } else if(alertForEdit.priority === "NORMAL") {
                    alertForEdit.alert_level = "1";
                } else {
                    alertForEdit.alert_level = "2";
                }

                alertForEdit.description = alertForEdit.content;
                alertForEdit.link = (alertForEdit.link ? alertForEdit.link : "");
            }

            state.actionsLoading = false;
            state.alertForEdit = alertForEdit;
            state.error = null;
        },
        alertsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        productsFetched: (state, action) => {
            const { products } = action.payload;
            state.products = products.map((product) => ({
              value: product.id,
              label: product.name,
              products: product.products,
            }));
            state.error = null;
            state.actionsLoading = false;
        },
        rolesFetched: (state,action) => {
            const { roles } = action.payload;
            state.roles = roles.map((role) => ({ value: role.id, label: role.name }));
            state.error = null;
            state.listLoading = false;
        },
        alertCreated: (state, action) => {
            state.alertForEdit = undefined;
            state.error = null;
            state.actionsLoading = false;
          },
        alertUpdated: (state, action) => {
            state.alertForEdit = undefined;
            state.error = null;
            state.actionsLoading = false;
        },
        alertDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        alertPublished: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        alertUnpublished: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
    },
});