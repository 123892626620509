import React, { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import * as actions from "../../../_redux/home/homeActions";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useBookmarkUIContext } from "../BookmarksByProductsContext";

const BookmarksByProductsResult = ({ openModal }) => {
  const { REACT_APP_BASE_URL } = process.env;
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({
      currentState: state.home,
    }),
    shallowEqual
  );
  const { showBookmarksByProducts, listLoading } = currentState;
  const { totalCount, filter } = showBookmarksByProducts;

  const bookmarkUIContext = useBookmarkUIContext();
  const bookmarkUIProps = useMemo(() => {
    return {
      queryParams: bookmarkUIContext.queryParams,
      setQueryParams: bookmarkUIContext.setQueryParams,
    };
  }, [bookmarkUIContext]);

  const pageChange = (newPageSize, newPageNumber) => {
    bookmarkUIProps.setQueryParams((prev) => ({
      ...prev,
      pageSize: newPageSize,
      pageNumber: newPageNumber,
    }));

    dispatch(
      actions.updatePaginationBookmarksByProduct(newPageSize, newPageNumber)
    );
  };

  useEffect(() => {
    dispatch(actions.fetchBookmarksByProducts(bookmarkUIProps.queryParams));
  }, [openModal, bookmarkUIProps.queryParams, dispatch]);

  const getNoImageByType = (knowledgeType) => {
    switch (knowledgeType) {
      case "faq":
        return "Books-14_2-color.svg";
      case "video":
        return "Communication-25_2-color.svg";
      case "link":
        return "UIExpressive_15_2-color.svg";
      case "manual":
        return "Books-07_2-color.svg";
      case "news":
        return "Clouds-03_2-color.svg";
      default:
        return "Books-14_2-color.svg";
    }
  };

  return (
    <>
      {showBookmarksByProducts?.items?.length === 0 ? (
        <div
          className="fade alert alert-secondary show"
          style={{ textAlign: "center" }}
        >
          <FormattedMessage id="GENERAL_SEARCH.EMPTY_DATA.MESSAGE" />
        </div>
      ) : (
        <>
          <div>
            <div className="mb-10">
              {showBookmarksByProducts?.items?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="d-flex align-items-center p-5 mb-5"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                  >
                    <div className="symbol symbol-60 symbol-light mr-5">
                      <span
                        className="symbol-label"
                        style={{
                          backgroundImage:
                            item.type === "knowledge"
                              ? `url(${REACT_APP_BASE_URL}/knowledge/${
                                  item.icon
                                }) , url(${toAbsoluteUrl(
                                  `/media/svg/icons/Thomson/${getNoImageByType(
                                    item.contentType ?? item.contenttype
                                  )}`
                                )})`
                              : `url(${REACT_APP_BASE_URL}/course/${
                                  item.icon
                                }), url(${toAbsoluteUrl(
                                  "/media/svg/icons/Thomson/Books-01_2-color.svg"
                                )})`,
                        }}
                      ></span>
                    </div>
                    <div
                      style={{ overflow: "auto" }}
                      className="d-flex flex-column flex-grow-1 font-weight-bolder"
                    >
                      <a
                        href={
                          item.type === "knowledge"
                            ? `/knowledge-base/view/${item.id}`
                            : `/learning/mycourses/${item.id}/view/class`
                        }
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {item.name}
                      </a>
                      <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
                        {item.product}
                      </div>
                      <span className="text-muted">{item.keywords}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="row"
            style={{ position: "absolute", bottom: 20, right: 35 }}
          >
            <>
              <Pagination
                isLoading={listLoading}
                innerClass="pagination my-auto pagination-md"
                itemClass="page-item"
                linkClass="page-link"
                activePage={filter.pageNumber}
                itemsCountPerPage={5}
                totalItemsCount={parseInt(totalCount)}
                pageRangeDisplayed={5}
                onChange={(pageNumber) => {
                  pageChange(5, pageNumber);
                }}
              />
            </>
          </div>
        </>
      )}
    </>
  );
};

export default BookmarksByProductsResult;
