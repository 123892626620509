import React from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

import { CanNavLink } from "../../../../../app/routes/Can";

import { shallowEqual, useSelector } from "react-redux";
import { rolesConfig as roles } from "../../../../../app/utils/roles";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const {
    views,
    currentCourse,
    certificate,
    tests,
    exam,
    currentCourseEvaluation,
    sections,
    test,
    contents,
    courses,
  } = useSelector(
    (state) => ({
      views: state.views,
      contents: state.contents,
      currentCourse: state?.courses?.currentCourse,
      courses: state?.courses,
      tests: state?.courses?.tests,
      exam: state?.courses?.exam,
      certificate: state?.certificate,
      currentCourseEvaluation: state?.courses?.currentCourseEvaluation,
      sections: state?.courses?.sections,
      test: state?.tests?.testForEdit,
    }),
    shallowEqual
  );

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <CanNavLink perform={roles.HOME.INDEX}>
          <li className={`menu-item ${getMenuItemActive("/home", false)}`}>
            <NavLink className="menu-link" to="/home">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
              </span>
              <span className="menu-text">
                <FormattedMessage id="PAGE.HOME" />
              </span>
            </NavLink>
          </li>
        </CanNavLink>

        <CanNavLink perform={roles.ALERTS.INDEX}>
          <li className={`menu-item ${getMenuItemActive("/alerts", false)}`}>
            <NavLink className="menu-link" to="/alerts">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")}
                />
              </span>
              <span className="menu-text">
                <FormattedMessage id="PAGE.ALERTS" />
              </span>
            </NavLink>
          </li>
        </CanNavLink>

        {/* knowledge-base */}
        <CanNavLink perform={roles.KNOWLEDGE.INDEX}>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/knowledge-base",
              true
            )}`}
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/knowledge-base">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Bulb1.svg")} />
              </span>
              <span className="menu-text">
                <FormattedMessage id="PAGE.KNOWLEDGE" />
              </span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <CanNavLink perform={roles.KNOWLEDGE.MANAGEMENT.INDEX}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/knowledge-base/management",
                      true
                    )}`}
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/knowledge-base/management"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.KNOWLEDGE.MANAGEMENT" />
                      </span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        {/*
                        <CanNavLink
                          perform={roles.KNOWLEDGE.MANAGEMENT.DASHBOARD}
                        >
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/knowledge-base/management/dashboard"
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/knowledge-base/management/dashboard"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="PAGE.KNOWLEDGE.MANAGEMENT.DASHBOARD" />
                              </span>
                            </NavLink>
                          </li>
                        </CanNavLink>
                            */}
                        <CanNavLink
                          perform={roles.KNOWLEDGE.MANAGEMENT.CONTENT}
                        >
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/knowledge-base/management/content",
                              true
                            )} ${
                              (contents?.currentKnowledgeEvaluation ||
                                contents?.contentForEdit) &&
                              "menu-item-open"
                            }`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/knowledge-base/management/content"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="PAGE.KNOWLEDGE.MANAGEMENT.CONTENT" />
                              </span>
                            </NavLink>
                            {contents?.currentKnowledgeEvaluation && (
                              <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                  <li
                                    className={`menu-item ${getMenuItemActive(
                                      `/knowledge-base/management/content/${contents?.currentKnowledgeEvaluation?.knowledge?.id}/evaluations`
                                    )}`}
                                  >
                                    <NavLink
                                      className="menu-link"
                                      to={`/knowledge-base/management/content/${contents?.currentKnowledgeEvaluation?.knowledge?.id}/evaluations`}
                                    >
                                      <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                      </i>
                                      <span
                                        className="menu-text"
                                        style={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          display: "inline-block",
                                          margin: "auto",
                                        }}
                                      >
                                        {
                                          contents?.currentKnowledgeEvaluation
                                            ?.knowledge?.title
                                        }
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            )}
                            {contents?.contentForEdit && (
                              <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                  <li
                                    className={`menu-item ${getMenuItemActive(
                                      `/knowledge-base/management/content/${contents?.contentForEdit?.id}/edit`
                                    )}`}
                                  >
                                    <NavLink
                                      className="menu-link"
                                      to={`/knowledge-base/management/content/${contents?.contentForEdit?.id}/edit`}
                                    >
                                      <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                      </i>
                                      <span
                                        className="menu-text"
                                        style={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          display: "inline-block",
                                          margin: "auto",
                                        }}
                                      >
                                        {contents?.contentForEdit?.title}
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </li>
                        </CanNavLink>
                      </ul>
                    </div>
                  </li>
                </CanNavLink>

                <CanNavLink perform={roles.KNOWLEDGE.FAQS}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/knowledge-base/faqs",
                      true
                    )} ${
                      views?.currentKnowledge?.knowledge_type === "faq" &&
                      "menu-item-open"
                    }`}
                  >
                    <NavLink className="menu-link" to="/knowledge-base/faqs">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.KNOWLEDGE.FAQS" />
                      </span>
                    </NavLink>
                    {views?.currentKnowledge &&
                      views?.currentKnowledge?.knowledge_type === "faq" && (
                        <div className="menu-submenu">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            <li
                              className={`menu-item ${getMenuItemActive(
                                `/knowledge-base/view/${views?.currentKnowledge?.id}`
                              )}`}
                            >
                              <NavLink
                                className="menu-link"
                                to={`/knowledge-base/view/${views?.currentKnowledge?.id}`}
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span
                                  className="menu-text"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    margin: "auto",
                                  }}
                                >
                                  {views?.currentKnowledge?.title}
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                  </li>
                </CanNavLink>
                <CanNavLink perform={roles.KNOWLEDGE.VIDEOS}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/knowledge-base/videos",
                      true
                    )} ${
                      views?.currentKnowledge?.knowledge_type === "video" &&
                      "menu-item-open"
                    }`}
                  >
                    <NavLink className="menu-link" to="/knowledge-base/videos">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.KNOWLEDGE.VIDEOS" />
                      </span>
                    </NavLink>
                    {views?.currentKnowledge &&
                      views?.currentKnowledge?.knowledge_type === "video" && (
                        <div className="menu-submenu">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            <li
                              className={`menu-item ${getMenuItemActive(
                                `/knowledge-base/view/${views?.currentKnowledge?.id}`
                              )}`}
                            >
                              <NavLink
                                className="menu-link"
                                to={`/knowledge-base/view/${views?.currentKnowledge?.id}`}
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span
                                  className="menu-text"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    margin: "auto",
                                  }}
                                >
                                  {views?.currentKnowledge?.title}
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                  </li>
                </CanNavLink>
                <CanNavLink perform={roles.KNOWLEDGE.MANUALS}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/knowledge-base/manuals",
                      true
                    )} ${
                      views?.currentKnowledge?.knowledge_type === "manual" &&
                      "menu-item-open"
                    }`}
                  >
                    <NavLink className="menu-link" to="/knowledge-base/manuals">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.KNOWLEDGE.MANUALS" />
                      </span>
                    </NavLink>
                    {views?.currentKnowledge &&
                      views?.currentKnowledge?.knowledge_type === "manual" && (
                        <div className="menu-submenu">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            <li
                              className={`menu-item ${getMenuItemActive(
                                `/knowledge-base/view/${views?.currentKnowledge?.id}`
                              )}`}
                            >
                              <NavLink
                                className="menu-link"
                                to={`/knowledge-base/view/${views?.currentKnowledge?.id}`}
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span
                                  className="menu-text"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    margin: "auto",
                                  }}
                                >
                                  {views?.currentKnowledge?.title}
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                  </li>
                </CanNavLink>
                <CanNavLink perform={roles.KNOWLEDGE.NEWS}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/knowledge-base/updates",
                      true
                    )} ${
                      views?.currentKnowledge?.knowledge_type === "news" &&
                      "menu-item-open"
                    }`}
                  >
                    <NavLink className="menu-link" to="/knowledge-base/updates">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.KNOWLEDGE.NEWS" />
                      </span>
                    </NavLink>
                    {views?.currentKnowledge &&
                      views?.currentKnowledge?.knowledge_type === "news" && (
                        <div className="menu-submenu">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            <li
                              className={`menu-item ${getMenuItemActive(
                                `/knowledge-base/view/${views?.currentKnowledge?.id}`
                              )}`}
                            >
                              <NavLink
                                className="menu-link"
                                to={`/knowledge-base/view/${views?.currentKnowledge?.id}`}
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span
                                  className="menu-text"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    margin: "auto",
                                  }}
                                >
                                  {views?.currentKnowledge?.title}
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                  </li>
                </CanNavLink>
                <CanNavLink perform={roles.KNOWLEDGE.LINKS}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/knowledge-base/links",
                      true
                    )} ${
                      views?.currentKnowledge?.knowledge_type === "link" &&
                      "menu-item-open"
                    }`}
                  >
                    <NavLink className="menu-link" to="/knowledge-base/links">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.KNOWLEDGE.LINKS" />
                      </span>
                    </NavLink>
                    {views?.currentKnowledge &&
                      views?.currentKnowledge?.knowledge_type === "link" && (
                        <div className="menu-submenu">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            <li
                              className={`menu-item ${getMenuItemActive(
                                `/knowledge-base/view/${views?.currentKnowledge?.id}`
                              )}`}
                            >
                              <NavLink
                                className="menu-link"
                                to={`/knowledge-base/view/${views?.currentKnowledge?.id}`}
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span
                                  className="menu-text"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    margin: "auto",
                                  }}
                                >
                                  {views?.currentKnowledge?.title}
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                  </li>
                </CanNavLink>
                <CanNavLink perform={roles.KNOWLEDGE.NEWSLETTER}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/knowledge-base/newsletter",
                      true
                    )} ${
                      views?.currentKnowledge?.knowledge_type ===
                        "newsletter" && "menu-item-open"
                    }`}
                  >
                    <NavLink
                      className="menu-link"
                      to="/knowledge-base/newsletter"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.KNOWLEDGE.NEWSLETTER.TITLE" />
                      </span>
                    </NavLink>
                    {views?.currentKnowledge &&
                      views?.currentKnowledge?.knowledge_type ===
                        "newsletter" && (
                        <div className="menu-submenu">
                          <i className="menu-arrow" />
                          <ul className="menu-subnav">
                            <li
                              className={`menu-item ${getMenuItemActive(
                                `/knowledge-base/view/${views?.currentKnowledge?.id}`
                              )}`}
                            >
                              <NavLink
                                className="menu-link"
                                to={`/knowledge-base/view/${views?.currentKnowledge?.id}`}
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span
                                  className="menu-text"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    margin: "auto",
                                  }}
                                >
                                  {views?.currentKnowledge?.title}
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                  </li>
                </CanNavLink>
              </ul>
            </div>
          </li>
        </CanNavLink>

        {/* learning */}
        <CanNavLink perform={roles.LEARNING.INDEX}>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/learning",
              true
            )}`}
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/learning">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">
                <FormattedMessage id="PAGE.LEARNING" />
              </span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <CanNavLink perform={roles.LEARNING.MANAGEMENT.INDEX}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/learning/management",
                      true
                    )}`}
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/learning/management"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.LEARNING.MANAGEMENT" />
                      </span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <CanNavLink perform={roles.LEARNING.MANAGEMENT.COURSE}>
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/learning/management/courses",
                              true
                            )}  ${
                              ((currentCourseEvaluation && !currentCourse) ||
                                (sections && courses?.isManagement) ||
                                (test && !currentCourse)) &&
                              "menu-item-open"
                            }`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/learning/management/courses"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="PAGE.SUB.LEARNING.MANAGEMENT.COURSES" />
                              </span>
                            </NavLink>

                            {currentCourseEvaluation && !currentCourse && (
                              <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                  <li
                                    className={`menu-item ${getMenuItemActive(
                                      `/learning/management/course/${currentCourseEvaluation?.course?.id}/evaluations`
                                    )} ${
                                      currentCourseEvaluation &&
                                      "menu-item-open"
                                    }`}
                                  >
                                    <NavLink
                                      className="menu-link"
                                      to={`/learning/management/course/${currentCourseEvaluation?.course?.id}/evaluations`}
                                    >
                                      <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                      </i>
                                      <span
                                        className="menu-text"
                                        style={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          display: "inline-block",
                                          margin: "auto",
                                        }}
                                      >
                                        {currentCourseEvaluation?.course?.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            )}
                            {sections && courses?.isManagement && (
                              <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                  <li
                                    className={`menu-item ${getMenuItemActive(
                                      `/learning/management/sections/course/${currentCourse?.id}`
                                    )} ${sections && "menu-item-open"}`}
                                  >
                                    <NavLink
                                      className="menu-link"
                                      to={`/learning/management/sections/course/${currentCourse?.id}`}
                                    >
                                      <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                      </i>
                                      <span
                                        className="menu-text"
                                        style={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          display: "inline-block",
                                          margin: "auto",
                                        }}
                                      >
                                        {currentCourse?.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            )}
                            {test && !currentCourse && (
                              <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                  <li
                                    className={`menu-item ${getMenuItemActive(
                                      `/learning/management/course/${test?.course_id}/exam`
                                    )} ${sections && "menu-item-open"}`}
                                  >
                                    <NavLink
                                      className="menu-link"
                                      to={`/learning/management/course/${test?.course_id}/exam`}
                                    >
                                      <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                      </i>
                                      <span
                                        className="menu-text"
                                        style={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          display: "inline-block",
                                          margin: "auto",
                                        }}
                                      >
                                        {test?.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </li>
                        </CanNavLink>
                        {/*
                        <CanNavLink
                          perform={roles.LEARNING.MANAGEMENT.DASHBOARD}
                        >
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/learning/management/dashboard"
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/learning/management/dashboard"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="PAGE.SUB.LEARNING.MANAGEMENT.DASHBOARD" />
                              </span>
                            </NavLink>
                          </li>
                        </CanNavLink>
                            */}
                        {/* <CanNavLink perform={roles.LEARNING.MANAGEMENT.TESTS}>
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/learning/management/tests"
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/learning/management/tests"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="PAGE.SUB.LEARNING.MANAGEMENT.TESTS" />
                              </span>
                            </NavLink>
                          </li>
                        </CanNavLink> */}
                        {/* <CanNavLink perform={roles.LEARNING.MANAGEMENT.CERTS}>
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/learning/management/certs"
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/learning/management/certs"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="PAGE.SUB.LEARNING.MANAGEMENT.CERTS" />
                              </span>
                            </NavLink>
                          </li>
                        </CanNavLink> */}
                      </ul>
                    </div>
                  </li>
                </CanNavLink>
                <CanNavLink perform={roles.LEARNING.REPOSITORY}>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/learning/repository"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/learning/repository">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.LEARNING.REPOSITORY" />
                      </span>
                    </NavLink>
                  </li>
                </CanNavLink>

                <CanNavLink perform={roles.LEARNING.MYCOURSES}>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/learning/mycourses",
                      true
                    )} ${
                      ((currentCourse && !tests && !sections) ||
                        (currentCourse && exam)) &&
                      "menu-item-open"
                    }`}
                  >
                    <NavLink className="menu-link" to="/learning/mycourses">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.LEARNING.MYCOURSES" />
                      </span>
                    </NavLink>
                    {((currentCourse && !tests && sections) ||
                      (currentCourse && exam)) && (
                      <div className="menu-submenu">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                          <li
                            className={`menu-item ${getMenuItemActive(
                              `/learning/mycourses/${currentCourse?.id}/view/class`
                            )} ${
                              ((currentCourse && !tests && !sections) ||
                                (currentCourse && exam)) &&
                              "menu-item-open"
                            }`}
                          >
                            <NavLink
                              className="menu-link"
                              to={`/learning/mycourses/${currentCourse?.id}/view/class`}
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span
                                className="menu-text"
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  display: "inline-block",
                                  margin: "auto",
                                }}
                              >
                                {currentCourse?.name}
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </CanNavLink>

                <CanNavLink perform={roles.LEARNING.MYCOURSES}>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/learning/mycompletedcourses",
                      true
                    )} ${!!certificate?.courseName && "menu-item-open"}`}
                  >
                    <NavLink
                      className="menu-link"
                      to="/learning/mycompletedcourses"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.LEARNING.MYCOURSES_COMPLETED" />
                      </span>
                    </NavLink>
                    {(certificate?.courseName || tests) && (
                      <div className="menu-submenu">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                          <li
                            className={`menu-item ${getMenuItemActive(
                              `/learning/mycourses/${certificate?.courseId}/view/class`,
                              true
                            )} ${
                              (certificate?.courseName || tests) &&
                              "menu-item-open"
                            }`}
                          >
                            <NavLink
                              className="menu-link"
                              to={`/learning/mycourses/${
                                certificate?.courseId ?? currentCourse?.id
                              }/view/class`}
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span
                                className="menu-text"
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  display: "inline-block",
                                  margin: "auto",
                                }}
                              >
                                {certificate?.courseName ?? currentCourse?.name}
                              </span>
                            </NavLink>
                            {certificate?.certificateUrl && (
                              <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                  <li
                                    className={`menu-item ${getMenuItemActive(
                                      `/learning/certificate${certificate?.courseId}/`,
                                      true
                                    )} ${
                                      !!certificate?.courseName &&
                                      "menu-item-open"
                                    }`}
                                  >
                                    <NavLink
                                      className="menu-link"
                                      to={`/learning/certificate/${certificate?.courseId}/`}
                                    >
                                      <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                      </i>
                                      <span
                                        className="menu-text"
                                        style={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          display: "inline-block",
                                          margin: "auto",
                                        }}
                                      >
                                        <FormattedMessage id="PAGE.SUB.LEARNING.CERTIFICATE.VIEW" />
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            )}
                            {tests && (
                              <div className="menu-submenu">
                                <i className="menu-arrow" />
                                <ul className="menu-subnav">
                                  <li
                                    className={`menu-item ${getMenuItemActive(
                                      `/learning/mycompletedcourses/tests/${currentCourse?.id}/`,
                                      true
                                    )} ${tests && "menu-item-open"}`}
                                  >
                                    <NavLink
                                      className="menu-link"
                                      to={`/learning/mycompletedcourses/tests/${currentCourse?.id}/`}
                                    >
                                      <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                      </i>
                                      <span
                                        className="menu-text"
                                        style={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          display: "inline-block",
                                          margin: "auto",
                                        }}
                                      >
                                        <FormattedMessage id="LEARNING.EXAM.ATTEMPT.TITLE" />
                                      </span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </CanNavLink>

                {/*
                <CanNavLink perform={roles.LEARNING.MYCERTS}>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/learning/mycerts"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/learning/mycerts">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.LEARNING.MYCERTS" />
                      </span>
                    </NavLink>
                  </li>
                </CanNavLink>
                */}
              </ul>
            </div>
          </li>
        </CanNavLink>

        {/* system */}
        <CanNavLink perform={roles.SYSTEM.INDEX}>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/system",
              true
            )}`}
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/system">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
              </span>
              <span className="menu-text">
                <FormattedMessage id="PAGE.SYSTEM" />
              </span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <CanNavLink perform={roles.SYSTEM.TEMPLATES}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/system/templates",
                      true
                    )}`}
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/system/templates"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="MENU.SYSTEM.TEMPLATES" />
                      </span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <CanNavLink perform={roles.SYSTEM.TEMPLATES}>
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/system/templates/emails"
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/system/templates/emails"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="MENU.SYSTEM.TEMPLATES.EMAILS" />
                              </span>
                            </NavLink>
                          </li>
                        </CanNavLink>
                        <CanNavLink perform={roles.SYSTEM.TEMPLATES}>
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/system/templates/certificates"
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to="/system/templates/certificates"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">
                                <FormattedMessage id="MENU.SYSTEM.TEMPLATES.CERTIFICATES" />
                              </span>
                            </NavLink>
                          </li>
                        </CanNavLink>
                      </ul>
                    </div>
                  </li>
                </CanNavLink>
              </ul>
            </div>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <CanNavLink perform={roles.SYSTEM.USERS}>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/system/users"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/system/users">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.SYSTEM.USERS" />
                      </span>
                    </NavLink>
                  </li>
                </CanNavLink>
                <CanNavLink perform={roles.SYSTEM.SETTINGS}>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/system/settings"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/system/settings">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.SYSTEM.SETTINGS" />
                      </span>
                    </NavLink>
                  </li>
                </CanNavLink>
                <CanNavLink perform={roles.SYSTEM.ASSETS}>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/system/assets"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/system/assets">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.SYSTEM.ASSETS" />
                      </span>
                    </NavLink>
                  </li>
                </CanNavLink>
                <CanNavLink perform={roles.SYSTEM.SETTINGS}>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/system/banners"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/system/banners">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.SYSTEM.BANNERS" />
                      </span>
                    </NavLink>
                  </li>
                </CanNavLink>
                {/*
                <CanNavLink perform={roles.SYSTEM.REPORTS}>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/system/reports"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/system/reports">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        <FormattedMessage id="PAGE.SUB.SYSTEM.REPORTS" />
                      </span>
                    </NavLink>
                  </li>
                </CanNavLink>
                    */}
              </ul>
            </div>
          </li>
        </CanNavLink>

        {/* -------------------------------------------------------------------------------------------------------- */}

        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/settings",
            true
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}
              />
            </span>
            <span className="menu-text">
              <FormattedMessage id="PAGE.CONFIGURATIONS" />
            </span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive("/settings/users")}`}
              >
                <NavLink className="menu-link" to="/settings/users">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="PAGE.SUB.CONFIGURATIONS.USERS" />
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/settings/system")}`}
              >
                <NavLink className="menu-link" to="/settings/system">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="PAGE.SUB.CONFIGURATIONS.SYSTEM" />
                  </span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/settings/email")}`}
              >
                <NavLink className="menu-link" to="/settings/email">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="PAGE.SUB.CONFIGURATIONS.EMAIL" />
                  </span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/settings/products"
                )}`}
              >
                <NavLink className="menu-link" to="/settings/products">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">
                    <FormattedMessage id="PAGE.SUB.CONFIGURATIONS.PRODUCT" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

        {/* ------------------------------------------------------------------------------------------ 
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/e-commerce",
            true
          )}`}
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/e-commerce">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">eCommerce</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive(
                  "/e-commerce/customers"
                )}`}
              >
                <NavLink className="menu-link" to="/e-commerce/customers">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Customers</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/e-commerce/products"
                )}`}
              >
                <NavLink className="menu-link" to="/e-commerce/products">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Products</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        */}
      </ul>
    </>
  );
}
