import * as requestFromServer from "./accomplishmentsCrud";
import { accomplishmentSlice, callTypes } from "./accomplishmentsSlice";

const { actions } = accomplishmentSlice;

export const fetchAccomplishment = (certificate_code) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getAccomplishment(
      certificate_code
    );
    dispatch(actions.accomplishmentFetched(response.data));
  } catch (error) {
    error.clientMessage = "Can't get accomplishment";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};
