/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";

export function QuickPanel() {
  const [selectedTab, setSelectedTab] = useState("Notifications");

  const setTab = (_tabName) => {
    setSelectedTab(_tabName);
  };

  return (
    <div id="kt_quick_panel" className="offcanvas offcanvas-right pt-5 pb-10">
      <Tab.Container defaultActiveKey={selectedTab}>
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <Nav
            onSelect={setTab}
            as="ul"
            role="tablist"
            className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          >
            <Nav.Item className="nav-item" as="li">
              <Nav.Link
                eventKey="Notifications"
                className={`nav-link ${
                  selectedTab === "Notifications" ? "active" : ""
                }`}
              >
                Notifications
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                eventKey="Settings"
                className={`nav-link ${
                  selectedTab === "Settings" ? "active" : ""
                }`}
              >
                Settings
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <div
            className="offcanvas-close mt-n1 pr-5"
            style={{ position: "absolute", top: "15px", right: "10px" }}
          >
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
        </div>

        <div className="offcanvas-content px-10">
          <div className="tab-content">
            <div
              id="kt_quick_panel_notifications"
              role="tabpanel"
              className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps ${
                selectedTab === "Notifications" ? "active show" : ""
              }`}
            >
              <div className="navi navi-icon-circle navi-spacer-x-0">
                <a href="#" className="navi-item">
                  <div className="navi-link rounded">
                    <div className="symbol symbol-50 mr-3">
                      <div className="symbol-label">
                        <i className="flaticon-bell text-success icon-lg"></i>
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold font-size-lg">
                        5 new user generated report
                      </div>
                      <div className="text-muted">Reports based on sales</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              id="kt_quick_panel_settings"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${
                selectedTab === "Settings" ? "active show" : ""
              }`}
            >
              <form className="form">
                <div>
                  <h5 className="font-weight-bold mb-3">Customer Care</h5>
                  <div className="form-group mb-0 row">
                    <label className="col-8 col-form-label">
                      Enable Notifications:
                    </label>
                    <div className="col-4 text-right">
                      <span className="switch switch-success switch-sm">
                        <label>
                          <input
                            type="checkbox"
                            onChange={() => {}}
                            checked
                            name=""
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="separator separator-dashed mt-8 mb-5" />
                <div>
                  <h5 className="font-weight-bold mb-3">Title:</h5>
                  <div className="form-group mb-0 row">
                    <label className="col-8 col-form-label">Label:</label>
                    <div className="col-4 text-right"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
}
