import { createSlice } from "@reduxjs/toolkit";

const initialHomeState = {
  listLoading: false,
  actionsLoading: false,
  requestsLoading: false,
  lastUserViews: [],
  showBookmarksByType: [],
  showBookmarksByProducts: {
    items: [],
    totalCount: undefined,
    filter: {
      pageNumber: 1,
      pageSize: 5,
    },
  },
  currentBookmarksContent: [],
  currentBookmarkedProduct: [],
  totalViews: [],
  accessRequests: [],
  lastError: null,
  roles: [],
  rolePermissions: [],
  products: [],
  userProducts: [],
  availableRequests: [],
  bookmarks: [],
  latestNews: [],
  latestMovies: [],
  filter: {
    pageNumber: 1,
    pageSize: 10,
  },
  notificationsUnreceived: [],
  notificationUnreadCountByType: [],
  warningsEntities: [],
  warningsEntitiesTotalCount: 0,
  notificationsEntities: [],
  notificationsEntitiesTotalCount: 0,
  latestNewsLoading: false,
  latestMoviesLoading: false,
  warningsEntitiesLoading: false,
  notificationsEntitiesLoading: false,
};

export const callTypes = {
  list: "list",
  action: "action",
  requests: "requests",
  notificationsList: "notificationsList",
  latestNewsList: "latestNewsList",
  latestMoviesList: "latestMoviesList",
};

export const homeSlice = createSlice({
  name: "home",
  initialState: initialHomeState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.requests) {
        state.requestsLoading = true;
      } else if (action.payload.callType === callTypes.notificationsList) {
        state.notificationsEntitiesLoading = true;
      } else if (action.payload.callType === callTypes.warningsList) {
        state.warningsEntitiesLoading = true;
      } else if (action.payload.callType === callTypes.latestNewsList) {
        state.latestNewsLoading = true;
      } else if (action.payload.callType === callTypes.latestMoviesList) {
        state.latestMoviesLoading = true;
      }
    },
    statisticsFetched: (state, action) => {
      const {
        lastUserViews,
        totalViews,
        showBookmarksByType,
        notificationUnreadCountByType,
        notificationsUnreceived,
        pickUpRecentNewsletter
      } = action.payload;
      state.showBookmarksByType = showBookmarksByType;
      state.pickUpRecentNewsletter = pickUpRecentNewsletter;
      state.lastUserViews = lastUserViews;
      state.notificationsUnreceived = notificationsUnreceived;
      state.notificationUnreadCountByType = notificationUnreadCountByType;
      state.totalViews = totalViews;
      state.listLoading = false;
      state.error = null;
    },
    bookmarksByProductsFetched: (state, action) => {
      const { showBookmarksByProducts, totalCount } = action.payload;
      state.showBookmarksByProducts.items = showBookmarksByProducts;
      state.showBookmarksByProducts.totalCount = totalCount;
      state.listLoading = false;
      state.error = null;
    },
    paginationBookmarksByProduct: (state, action) => {
      const { pageNumber, pageSize } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.showBookmarksByProducts.filter.pageNumber = pageNumber;
      state.showBookmarksByProducts.filter.pageSize = pageSize;
    },
    rolesFetched: (state, action) => {
      const { roles } = action.payload;
      state.roles = roles.map((role) => ({ value: role.id, label: role.name }));
      state.rolePermissions = roles.map((role) => ({
        id: role.id,
        permissions: role.permissions,
      }));
      state.error = null;
      state.listLoading = false;
    },
    productsFetched: (state, action) => {
      const { products } = action.payload;
      state.products = products.map((product) => ({
        value: product.id,
        label: product.name,
      }));
      state.error = null;
      state.listLoading = false;
    },
    userProductsFetched: (state, action) => {
      const { userProducts } = action.payload;
      state.userProducts = userProducts;
      state.error = null;
      state.listLoading = false;
    },
    requestsFetched: (state, action) => {
      state.availableRequests = action.payload.availableRequests;
      state.error = null;
      state.requestsLoading = false;
    },

    userApproved: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    userReproved: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    bookmarksContentFetched: (state, action) => {
      const { bookmarks, totalCount } = action.payload;
      state.bookmarks = bookmarks;
      state.totalCount = totalCount;
      state.error = null;
      state.listLoading = false;
    },
    paginationChanged: (state, action) => {
      const { pageNumber, pageSize } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.filter.pageNumber = pageNumber;
      state.filter.pageSize = pageSize;
    },
    bookmarkPost: (state, action) => {
      const {
        bookmark: { id },
        system_product_id,
      } = action.payload;

      const productIndex = state.userProducts.findIndex((product) => {
        return product.id === system_product_id;
      });

      if (productIndex !== -1) {
        state.userProducts[productIndex].bookmark = id;
      }
      state.actionsLoading = false;
      state.error = null;
    },
    bookmarkDeleted: (state, action) => {
      const { system_product_id } = action.payload;

      const productIndex = state.userProducts.findIndex((product) => {
        return product.id === system_product_id;
      });

      if (productIndex !== -1) {
        state.userProducts[productIndex].bookmark = null;
      }
      state.actionsLoading = false;
      state.error = null;
    },
    receivedNotificationsChecked: (state, action) => {
      state.notificationsUnreceived = [];
      state.error = null;
      state.actionsLoading = false;
    },
    warningsFetched: (state, action) => {
      const { entities, totalCount, notificationUnreadCountByType } = action.payload;
      state.warningsEntities = entities;
      state.warningsEntitiesTotalCount = totalCount;
      state.error = null;
      state.warningsEntitiesLoading = false;
      state.notificationUnreadCountByType = notificationUnreadCountByType;
    },
    notificationsFetched: (state, action) => {
      const { entities, totalCount, notificationUnreadCountByType } = action.payload;
      state.notificationsEntities = entities;
      state.notificationsEntitiesTotalCount = totalCount;
      state.error = null;
      state.notificationsEntitiesLoading = false;
      state.notificationUnreadCountByType = notificationUnreadCountByType;
    },
    latestMoviesFetched: (state, action) => {
      const { entities } = action.payload;
      state.latestMovies = entities;
      state.error = null;
      state.latestMoviesLoading = false;
    },
    latestNewsFetched: (state, action) => {
      const { entities } = action.payload;
      state.latestNews = entities;
      state.error = null;
      state.latestNewsLoading = false;
    },
  },
});
