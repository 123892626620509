import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "./_redux/accomplishments/accomplishmentsActions";

const Accomplishments = () => {
  const { certificate_code } = useParams();
  const dispatch = useDispatch();

  const { REACT_APP_BASE_URL } = process.env;

  useEffect(() => {
    dispatch(actions.fetchAccomplishment(certificate_code));
  }, [certificate_code, dispatch]);

  const { accomplishmentUrl } = useSelector(({ accomplishment }) => {
    return {
      accomplishmentUrl: accomplishment.accomplishmentUrl,
    };
  });

  return (
    <>
      <Helmet>
        <title>Certificado</title>
        <meta
          property="og:image"
          content={`${REACT_APP_BASE_URL}${accomplishmentUrl}`}
        />
      </Helmet>

      <div className="d-flex flex-column justify-content-md-center m-sm-12 my-md-auto">
        <div className="d-flex justify-content-center">
          <div className="col-sm-12 col-md-10">
            <span className="small">Course Certificate</span>
            <h1>Nome do Curso</h1>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-center">
          <div className="col-lg-5 my-3">
            <div className="card bg-primary text-white p-12">
              <h2>
                Completed by <b>PERSON_NAME</b>
              </h2>
              <b>June 25, 2021</b>
              <p style={{ maxHeight: "50vh" }} className="overflow-auto">
                true generator on the Internet. It uses a dictionary of over 200
                Latin words, combined with a handful of model sentence
                structures, to generate Lorem Ipsum which looks reasonable. The
                generated Lorem Ipsum is therefore always free from repetition,
                injected humour, or non-characteristic words etc.
              </p>
            </div>
          </div>
          <div
            style={{ maxHeight: "75vh" }}
            className="col-lg-5 my-3 overflow-hidden"
          >
            <img
              width="100%"
              src={`${REACT_APP_BASE_URL}${accomplishmentUrl}`}
              alt="cert"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Accomplishments;
