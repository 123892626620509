// const error = {
//   type: "",
//   message: "",
//   hasError: false,
// };

// const request = {
//   isLoading: false,
//   success: false,
//   error: error,
// };

// const pagination = {
//   pageNumber: 0,
//   pageSize: 0,
//   totalCount: 0,
// };

// const products = {
//   products: [],
//   request: request,
// };

// const courses = {
//   courses: [],
//   request: request,
//   pagination: pagination,
// };

// const sections = {
//   sections: [],
//   request: request,
// };

// const classes = {
//   classes: [],
//   request: request,
// };

// const management = {
//   courses: courses,
//   request: request,
//   pagination: pagination,
// };

// const studyOptions = {
//   courses: courses,
//   request: request,
//   pagination: pagination,
// };

// const myCourses = {
//   courses: courses,
//   request: request,
//   pagination: pagination,
// };

// export const initialState = {
//   management: management,
//   studyOptions: studyOptions,
//   myCourses: myCourses,

//   sections: sections,
//   classes: classes,
//   products: products,
// };

export const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  courses: [],
  products: [],
  sections: undefined,
  classes: [],
  error: null,
  bookmark: null,
  exam: "",
  examCorrected: "",
  loadingExam: false,
  filter: {
    pageNumber: 1,
    pageSize: 10,
  },
  currentCourseEvaluation: undefined,
  currentCourse: undefined,
  tests: undefined,
  attempt: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};
