import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import Swal from "sweetalert2";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { useLang } from "../../../../_metronic/i18n";

const initialValues = {
  name: "",
  email: "",
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_INPUT" }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_INPUT" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.INPUT" }))
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_INPUT" }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_INPUT" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  let language = useLang();

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();

      register(values.email, values.name, language.toUpperCase())
        .then(() => {
          disableLoading();
          setSubmitting(false);
          Swal.fire({
            title: intl.formatMessage({
              id: "PAGE.KNOWLEDGE.VIEW.SHARED_BY_EMAIL.TOAST_CONFIRM",
            }),
            text: intl.formatMessage({ id: "AUTH.REGISTER.SUCCESS" }),
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: intl.formatMessage({ id: "OPTION.OK" }),
          });
        })
        .catch((err) => {
          const { error } = err.response.data;

          if (error.includes("Already")) {
            setStatus(
              intl.formatMessage({
                id: "AUTH.REGISTRATION.VALIDATION.INVALID_EMAIL",
              })
            );
          } else {
            setStatus(
              intl.formatMessage({
                id: "AUTH.REGISTRATION.VALIDATION.ERROR",
              })
            );
          }

          setSubmitting(false);
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.CREATE_ACCOUNT.MESSAGE" />
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        <div className="form-group fv-plugins-icon-container d-flex align-items-center justify-content-between pl-3">
          <FormattedMessage id="LANGUAGE.TITLE.CHANGE" />:
          <LanguageSelectorDropdown />
        </div>
        {/* begin: name */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: "PLACEHOLDER.USER_PERSONAL_INFORMATION.NAME",
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        {/* end: name */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid || loading}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>
              <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
