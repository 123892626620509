import React from "react";
import BookmarkFilter from "./BookmarksByProductsFilter";
import { BookmarkUIProvider } from "./BookmarksByProductsContext";
import BookmarksByProductsResult from "./BookmarksByProductsResult";

const BookmarksByProducts = ({ openModal }) => {
  return (
    <BookmarkUIProvider>
      <BookmarkFilter />
      <div className="mt-5 pb-5">
        <BookmarksByProductsResult openModal={openModal} />
      </div>
    </BookmarkUIProvider>
  );
};

export default BookmarksByProducts;
