import { FormattedMessage } from "react-intl";

const UserWarningList = ({ entities, handleReadNotification }) => {
    return (
        <>
            {entities.map((warning) => (
                <a
                    key={warning.id}
                    href="#"
                    onClick={() => handleReadNotification(warning.notification_id)}
                    className={`mb-2 navi-item ${!warning.read ? 'bg-light-warning ' : ''}`}
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}
                >
                    <div className="navi-link">
                    <div className="navi-icon mr-2">
                        <i className="flaticon2-warning text-danger"></i>
                    </div>
                    <div className="navi-text">
                        <div className="font-weight-bold">
                        {warning.title ? warning.title : <FormattedMessage id="PAGE.ALERTS.MANAGEMENT.EDIT.TYPE.WARNING" />}
                        </div>
                        <div className="text-muted">
                            {new Date(warning.start_date).toLocaleDateString('pt-BR')}
                        </div>
                    </div>
                    </div>
                </a>
            ))}
        </>
    )
};

export default UserWarningList;