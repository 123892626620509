import { createSlice } from '@reduxjs/toolkit';

const initialCoursesManagementState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    courseForEdit: undefined,
    templates: undefined,
    lastError: null,
    products: []
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const coursesManagementSlice = createSlice({
    name: 'coursesManagement',
    initialState: initialCoursesManagementState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if(action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if(action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        courseFetched: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        coursesFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.totalCount = parseInt(totalCount);
            state.entities = entities;
            state.error = null;
            state.listLoading = false;
        },
        productsFetched: (state, action) => {
            const { products } = action.payload;
            state.products = products.map((product) => ({ value: product.id, label: product.name }));
            state.error = null;
            state.actionsLoading = false;
        },
        courseCreated: (state, action) => {
            state.courseForEdit = undefined;
            state.error = null;
            state.actionsLoading = false;
        },
        courseUpdated: (state, action) => {
            state.courseForEdit = undefined;
            state.error = null;
            state.actionsLoading = false;
        },
        setCourseForEdit: (state, action) => {
            const { courseForEdit } = action.payload;
            state.courseForEdit = courseForEdit;
            state.error = null;
            state.actionsLoading = false;
        },
        courseDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        coursePublished: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        courseunPublished: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        getTemplateOptions: (state, action) => {
            state.templates =  action.payload;
            state.error = null;
            state.listLoading = false;
        },
    },
});