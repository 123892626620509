import { createSlice } from "@reduxjs/toolkit";

const initialTestsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entitiesStatistics: [],
  statisticsTotalCount: 0,
  testForEdit: undefined,
  // {
  //   questions: [],
  //   name: "",
  //   description: "",
  //   min_grade: "",
  //   courseBuilding: true,
  //   testAlreadyAnswered: 0,
  // },
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const testsSlice = createSlice({
  name: "tests",
  initialState: initialTestsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error} ${action.payload.error.clientMessage}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    testFetched: (state, action) => {
      const { courseTest, totalAnswer } = action.payload;
      state.actionsLoading = false;
      state.error = null;

      if (!!courseTest) {
        const testeForEdit = {
          course_id: courseTest.course_id.id,
          courseBuilding: courseTest.course_id.building,
          testAlreadyAnswered: parseInt(totalAnswer[0].answers),
          testId: courseTest.id,
          description: courseTest.description,
          min_grade: courseTest.min_grade,
          interval: courseTest.interval,
          questions_by_test: courseTest.questions_by_test,
          name: courseTest.name,
          questions: courseTest.course_question.map((question) => {
            return {
              description: question.description,
              name: question.name,
              position: question.position,
              answers: question.course_answer_id.map((answer) => {
                return {
                  correct: answer.correct === true ? "1" : "0",
                  description: answer.description,
                  position: answer.position,
                };
              }),
            };
          }),
        };

        state.testForEdit = testeForEdit;
      } else {
        state.testForEdit = {
          questions: [],
          name: "",
          description: "",
          min_grade: "",
          courseBuilding: true,
          testAlreadyAnswered: 0,
        };
      }
    },
    testsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    testCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    testUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    questionCreated: (state, action) => {
      const { question } = action.payload;
      question.answers = [];
      state.testForEdit?.questions.push(question);
      state.error = null;
      state.actionsLoading = false;
    },
    questionUpdated: (state, action) => {
      const { index, question } = action.payload;
      state.testForEdit.questions[index] = question;
      state.error = null;
      state.actionsLoading = false;
    },
    questionDeleted: (state, action) => {
      const { index } = action.payload;
      state.testForEdit?.questions.splice(index, 1);
      state.error = null;
      state.actionsLoading = false;
    },
    answerCreated: (state, action) => {
      const { questionIndex, answer } = action.payload;
      state.testForEdit?.questions[questionIndex].answers.push(answer);
      state.error = null;
      state.actionsLoading = false;
    },
    answerUpdated: (state, action) => {
      const { questionIndex, answerIndex, answer } = action.payload;
      if (answer.correct == "1") {
        state.testForEdit?.questions[questionIndex].answers.forEach(
          (answer, index) => {
            state.testForEdit.questions[questionIndex].answers[index].correct =
              "0";
          }
        );
      }
      state.testForEdit.questions[questionIndex].answers[answerIndex] = answer;
      state.error = null;
      state.actionsLoading = false;
    },
    answerDeleted: (state, action) => {
      const { questionIndex, answerIndex } = action.payload;
      state.testForEdit?.questions[questionIndex].answers.splice(
        answerIndex,
        1
      );
      state.error = null;
      state.actionsLoading = false;
    },
    statisticsFetched: (state, action) => {
      const { entities, totalCount } = action.payload;
      state.entitiesStatistics = entities;
      state.statisticsTotalCount = totalCount;
      state.error = null;
      state.listLoading = false;
    },
    clear: (state, action) => {
      state.testForEdit = undefined;
    },
  },
});
