import { createSlice } from "@reduxjs/toolkit";

const initialVideosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  bookmark: null,
  filter: {
    pageNumber: 1,
    pageSize: 10,
  },
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const videosSlice = createSlice({
  name: "Videos",
  initialState: initialVideosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    videosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    paginationChanged: (state, action) => {
      const { pageNumber, pageSize } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.filter.pageNumber = pageNumber;
      state.filter.pageSize = pageSize;
    },
    bookmarkPost: (state, action) => {
      const { bookmark } = action.payload;
      state.bookmark = bookmark;
      state.actionsLoading = false;
      state.error = null;
    },
    bookmarkDeleted: (state, action) => {
      state.bookmark = null;
      state.actionsLoading = false;
      state.error = null;
    },
  },
});
