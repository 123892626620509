import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../_redux/home/homeActions";

import { Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import { FormattedMessage, injectIntl } from "react-intl";
import Bookmark from "../../../components/Bookmark";
import { Info } from "@material-ui/icons";

const perfectScrollbarOptions = {
  wheelSpeed: 0.8,
  wheelPropagation: false,
  scrollYMarginOffset: 1,
};

const ProductBookmarkModal = ({ openModal, setOpenModal, intl }) => {
  const dispatch = useDispatch();
  const saveBookmark = (requestBody) => {
    dispatch(actions.pushBookmark(requestBody)).then(() => {});
  };
  const deleteBookmark = (id, system_product_id) => {
    dispatch(actions.removeBookmark(id, system_product_id)).then(() => {});
  };

  useEffect(() => {
    openModal === true && dispatch(actions.fetchUserProducts()).then(() => {});
  }, [dispatch, openModal]);

  const { products, listLoading } = useSelector((state) => ({
    products: state.home.userProducts,
    listLoading: state.home.listLoading,
  }));

  if (products.length === 0) {
    return <></>;
  }

  const { REACT_APP_BASE_URL } = process.env;

  return (
    <Modal
      size="md"
      centered
      scrollable
      show={openModal}
      onHide={() => {
        setOpenModal(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="PAGE.HOME.BOOKMARK_BY_PRODUCT.MODAL.TITLE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <span
          style={{
            boxShadow: "inset 0px 0px 300px -200px rgba(0,0,0,0.44)",
          }}
          className="d-flex flex-row justify-content-center p-6"
        >
          <div className="d-flex justify-content-center align-items-center">
            <Info />
            <h6 className="m-0 p-0">
              <FormattedMessage id="PAGE.HOME.BOOKMARK_BY_PRODUCT.MODAL.HELPER" />
            </h6>
          </div>
        </span>
        <PerfectScrollbar
          options={perfectScrollbarOptions}
          className="scroll pt-3"
          style={{
            maxHeight: "350px",
            position: "relative",
          }}
        >
          <div className="px-8">
            {products.map((product, index) => (
              <div
                key={product.id + index}
                className="d-flex align-items-center mb-10"
              >
                <div className="symbol symbol-60 symbol-light mr-5">
                  <span
                    className="symbol-label"
                    style={{
                      backgroundImage: `${
                        product.icon
                          ? `url(${REACT_APP_BASE_URL}/products/${product.icon})`
                          : `url(${toAbsoluteUrl(
                              "/media/svg/illustrations/data-points.svg"
                            )})`
                      }`,
                    }}
                  ></span>
                </div>
                <div
                  style={{ overflow: "auto" }}
                  className="d-flex flex-column flex-grow-1 font-weight-bolder"
                >
                  <span className="text-dark text-hover-primary font-size-lg">
                    {product.name}
                  </span>
                  <span className="text-muted">{product.keywords}</span>
                </div>

                <Bookmark
                  handleDeleteBookmark={deleteBookmark}
                  handleSaveBookmark={saveBookmark}
                  type="product"
                  bookmark={product.bookmark}
                  id={product.id}
                />
              </div>
            ))}
          </div>
        </PerfectScrollbar>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={() => {
            setOpenModal(false);
          }}
          className="btn btn-light"
        >
          <i className="fa fa-arrow-left"></i>
          <FormattedMessage id="PAGE.KNOWLEDGE.VIEW.BACK" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default injectIntl(ProductBookmarkModal);
