import { createSlice } from '@reduxjs/toolkit';

const initialAssetsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    entitiesSub: [],
    assetForEdit: null,
    assetSubForEdit: null,
    lastError: null,
};

export const  callTypes = {
    list: "list",
    action: "action",
};

export const assetsSlice = createSlice({
    name: 'assets',
    initialState: initialAssetsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if(action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if(action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        assetFetched: (state, action) => {
            state.actionsLoading = false;
            state.assetForEdit = action.payload.asset;
            state.error = null;
        },
        assetsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        assetCreated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        assetUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        assetDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        assetsSubFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.entitiesSub = entities;
            state.totalCount = totalCount;
            state.error = null;
            state.listLoading = false;
        },
        assetSubFetched: (state, action) => {
            state.actionsLoading = false;
            state.assetSubForEdit = action.payload.asset;
            state.error = null;
        },
        assetSubCreated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        assetSubUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },
        assetSubDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        }
    }
});
