import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";
import { FormattedMessage } from "react-intl";
export function HeaderMenu({ layoutProps }) {
  return (
    <div
      id="kt_header_menu"
      className={`d-flex align-items-center header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Thomson/Globes-25_1-color.svg")} />
      </span>
      <h2 className="font-weight-bolder mb-0" >
        <FormattedMessage id="APP.NAME" />
      </h2>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*<li className={`menu-item menu-item-rel`}>Base de conhecimento</li>*/}
      </ul>
    </div>
  );
}
