import { ArrowBack, ArrowForward } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";

import { injectIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as bannersActions from "../../../modules/System/_redux/banners/bannersActions";
import { registerClick } from "../../../modules/System/_redux/banners/bannersCrud";

const { REACT_APP_BASE_URL } = process.env;

const BannersCarousel = ({ intl }) => {
  const [heightBanner, setHeightBanner] = useState(null);
  const [intervalTime, setIntervalTime] = useState(5000);
  const ref = useRef(null);

  const { banners = [] } = useSelector(
    (state) => ({ banners: state.banner.publics }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = banners.length;

  const autoScroll = true;
  let slideInterval;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  // set interval time of next banner
  useEffect(() => {
    setIntervalTime(
      banners[currentSlide === slideLength - 1 ? 0 : currentSlide + 1]
        ?.transition_duration * 1000
    );
  }, [currentSlide]);

  useEffect(() => {
    let BANNER_WIDTH = ref.current.offsetWidth;

    setHeightBanner(ref.current ? (BANNER_WIDTH * 400) / 1920 : 0);
    window.addEventListener("resize", () => {
      if (typeof heightBanner == "number") {
        setHeightBanner(
          ref.current ? (ref.current.offsetWidth * 400) / 1920 : 0
        );
      }
    });
  }, [ref.current]);

  function checkProtocolAndAddIt(url) {
    if (!!url && url?.indexOf("http") === -1) return "https://" + url;

    return url;
  }

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-body d-flex justify-content-center align-items-center">
          <ArrowBack
            className="bg-primary text-white mr-5"
            style={{
              padding: 3,
              borderRadius: "50%",
              fontSize: 30,
              cursor: "pointer",
            }}
            onClick={prevSlide}
          />
          <div
            ref={ref}
            style={{ overflow: "hidden", width: "100%", height: "100%" }}
          >
            <a
              style={{ width: "100%" }}
              href={checkProtocolAndAddIt(banners[currentSlide]?.link)}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                registerClick(banners[currentSlide]?.id);
              }}
            >
              <img
                alt="banners"
                ref={ref}
                src={`${REACT_APP_BASE_URL}/banner/${banners[currentSlide]?.file_id?.file_name}`}
                width="1920px"
                style={{
                  width: "100%",
                  height: `${heightBanner}px`,
                  backgroundSize: "100% auto",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  objectFit: "cover",
                  position: "relative",
                }}
                onError={(e) => {
                  dispatch(
                    bannersActions.removeBannerById(banners[currentSlide]?.id)
                  );
                  nextSlide();
                }}
              />
            </a>
          </div>

          <ArrowForward
            className="bg-primary text-white ml-5"
            style={{
              padding: 3,
              borderRadius: "50%",
              fontSize: 30,
              cursor: "pointer",
            }}
            onClick={nextSlide}
          />
        </div>
        <div className="d-flex align-self-center mb-5" style={{ gap: 10 }}>
          {banners.map((_, index) => {
            return (
              <span
                onClick={() => setCurrentSlide(index)}
                className={`bg-${
                  index === currentSlide ? "primary" : "secondary"
                }`}
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              ></span>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default injectIntl(BannersCarousel);
