import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { isEqual } from "lodash";
import { useBookmarkUIContext } from "../BookmarkUIContext";

const prepareFilter = (queryParams, values) => {
  const newQueryParams = { ...queryParams };

  newQueryParams.searchText = values.searchText;

  return newQueryParams;
};

function BookmarkFilter({ intl }) {
  const [searchValue, setSearchValue] = useState("");

  const { listLoading } = useSelector((state) => ({
    listLoading: state.home.listLoading,
  }));

  const bookmarkUIContext = useBookmarkUIContext();
  const bookmarkUIProps = useMemo(() => {
    return {
      setQueryParams: bookmarkUIContext.setQueryParams,
      queryParams: bookmarkUIContext.queryParams,
    };
  }, [bookmarkUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(bookmarkUIProps.queryParams, values);

    if (!isEqual(newQueryParams, bookmarkUIContext.queryParams)) {
      bookmarkUIProps.setQueryParams(newQueryParams);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(searchValue.replace(/\s/g, '') != '') {
        const requestBody = {
          searchText: searchValue,
        };

        applyFilter(requestBody);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const clear = () => {
    setSearchValue("");
  };

  return (
    <>
      <div className={clsx("quick-search border rounded bg-white m-0")}>
        <form className="quick-search-form">
          <div className="input-group">
            <div className={`input-group-prepend`}>
              <span className="input-group-text">
                <span className="svg-icon svg-icon-lg">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                  />
                </span>
              </span>
            </div>
            <input
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              type="text"
              autoFocus={false}
              placeholder={intl.formatMessage({
                id: "GENERAL_SEARCH.INPUT.PLACEHOLDER",
              })}
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
              }}
              className="form-control"
            />

            <div
              className={`input-group-append ${
                listLoading ? "spinner spinner-sm spinner-primary" : ""
              }")}`}
            >
              <span className="input-group-text">
                <i
                  style={{
                    display:
                      searchValue === "" || listLoading ? "none" : "flex",
                  }}
                  onClick={clear}
                  className="quick-search-close ki ki-close icon-sm text-muted"
                />
              </span>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default injectIntl(BookmarkFilter);
