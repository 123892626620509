import { createSlice } from "@reduxjs/toolkit";

const initialTemplatesState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  emails: {
    entities: [],
    totalCount: 0,
    emailForEdit: null,
  },
  certificates: {
    entities: [],
    totalCount: 0,
    certificateForEdit: {
      currentCertificate: null,
    },
  },
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState: initialTemplatesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    templateEmailsFetched: (state, action) => {
      const { emails, totalRecords } = action.payload;
      state.emails.entities = emails;
      state.emails.totalCount = totalRecords;

      state.error = null;
      state.listLoading = false;
    },
    createTemplate: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    findTemplateById: (state, action) => {
      const { template } = action.payload;
      state.emails.emailForEdit = template;
      state.error = null;
      state.listLoading = false;
    },
    updateTemplate: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    templateDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    templateCertificatesFetched: (state, action) => {
      const { courseTemplate, totalRecords } = action.payload;
      state.certificates.entities = courseTemplate;
      state.certificates.totalCount = totalRecords;

      state.error = null;
      state.listLoading = false;
    },
    createCertificateTemplate: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    findCertificateTemplateById: (state, action) => {
      const { courseTemplate, certificates, totalRecords } = action.payload;
      state.certificates.certificateForEdit = {
        ...courseTemplate,
        certificates,
        totalRecords,
      };
      state.error = null;
      state.listLoading = false;
    },
    updateCertificateTemplate: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    deleteTemplateCertificates: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    createCertificate: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    deleteCertificate: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    findCertificateById: (state, action) => {
      const { templateCertificate } = action.payload;

      state.certificates.certificateForEdit.currentCertificate = templateCertificate;
      state.error = null;
      state.listLoading = false;
    },
    updateCertificate: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
