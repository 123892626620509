import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

import { initialFilter } from "./BookmarkUIHelpers";

const BookmarkUIContext = createContext();

export function useBookmarkUIContext() {
  return useContext(BookmarkUIContext);
}

export const BookmarkUIConsumer = BookmarkUIContext.Consumer;

export function BookmarkUIProvider({ children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParams,
    setQueryParamsBase,
  };

  return (
    <BookmarkUIContext.Provider value={value}>
      {children}
    </BookmarkUIContext.Provider>
  );
}
