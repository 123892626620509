import { createSlice, current } from "@reduxjs/toolkit";

import { callTypes, initialState } from "./initialState";

export const courseSlice = createSlice({
  name: "courses",
  initialState: initialState,
  reducers: {
    updateManagementCourses(state, action) {
      const courseIndex = state.courses.findIndex((course) => {
        return course.id === action.payload.course.id;
      });
      if (courseIndex > -1) {
        state.courses[courseIndex] = action.payload.course;
      }
    },
    // -------------------------------------------------------------------------------
    courseEvaluated: (state, action) => {
      state.currentCourse.hasEvaluation = true;
      state.actionsLoading = false;
      state.error = null;
    },
    // -------------------------------------------------------------------------------
    setSections(state, action) {
      if (Array.isArray(action.payload.sections)) {
        state.sections = action.payload.sections;
      } else {
        state.sections = [...state.sections, action.payload.sections];
      }

      if (action.payload.management) {
        state.isManagement = action.payload.management;
      }

      state.currentCourse = action.payload.currentCourse;
      state.lastCourseId = action.payload?.currentCourse?.id;
      state.actionsLoading = false;
      state.listLoading = false;
    },
    resetSections(state, action) {
      state.sections = initialState.sections;
      state.currentCourse = {};
    },
    updateSection(state, action) {
      const sectionIndex = state.sections.findIndex((section) => {
        return section.id === action.payload.section.id;
      });

      if (sectionIndex >= 0) {
        state.sections[sectionIndex] = action.payload.section;
      }
    },
    removeSection(state, action) {
      const { id } = action.payload.section;
      state.sections = state.sections.filter((section) => {
        return section.id !== id;
      });
    },

    // -------------------------------------------------------------------------------
    setClass(state, action) {
      state.classes = action.payload.classes;
    },
    updateClass(state, action) {
      const { id, ...section } = action.payload.class;
      const classIndex = state.classes.findIndex((section) => {
        return section.id === id;
      });
      if (classIndex >= 0) {
        state.classes[classIndex] = section;
      }
    },
    removeClass(state, action) {
      const { id } = action.payload.class;
      state.classes = state.classes.filter((section) => {
        return section.id !== id;
      });
    },
    startCallClass(state, action) {
      state.listLoading = true;
      state.error = initialState.classes.request.error;
    },
    endCallClass(state, action) {
      state.classes.request.isLoading = false;
    },

    // -------------------------------------------------------------------------------

    setExamCourse: (state, action) => {
      state.exam = action.payload.exam;
    },
    setCorrectedExamCourse: (state, action) => {
      state.loadingExam = false;
      state.examCorrected = action.payload.exam;
    },
    startCallExam: (state, action) => {
      state.loadingExam = true;
    },

    // -------------------------------------------------------------------------------
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    setProducts(state, action) {
      state.products = action.payload.products.map((product) => ({
        value: product.id,
        label: product.name,
      }));
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    coursesFetched: (state, action) => {
      const { totalCount, courses } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.courses = courses;
      state.totalCount = totalCount;
    },
    bookmarkPost: (state, action) => {
      const {
        bookmark: { id },
        course_id,
      } = action.payload;
      const courseIndex = state.courses.findIndex(
        (course) => course.id === course_id
      );
      if (courseIndex !== -1) {
        state.courses[courseIndex].bookmark = id;
      }
      state.actionsLoading = false;
      state.error = null;
    },
    bookmarkDeleted: (state, action) => {
      const { course_id } = action.payload;

      const courseIndex = state.courses.findIndex(
        (course) => course.id === course_id
      );
      if (courseIndex !== -1) {
        state.courses[courseIndex].bookmark = null;
      }
      state.actionsLoading = false;
      state.error = null;
    },
    courseCreated: (state, action) => {
      const { courses } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.courses = [...state.courses, courses];
    },
    courseUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    courseDeleted: (state, action) => {
      const { course: courseDeleted } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.courses = state.courses.filter((course) => {
        return course.id !== courseDeleted.id;
      });
    },
    courseSituation: (state, action) => {
      const situation = action.payload;

      if (current(state).currentCourse) {
        state.currentCourse.situation = situation;
      }

      state.error = null;
      state.actionsLoading = false;
    },
    paginationChanged: (state, action) => {
      const { pageNumber, pageSize } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.filter.pageNumber = pageNumber;
      state.filter.pageSize = pageSize;
    },

    // -------------------------------------------------------------------------------
    evaluationsFetched: (state, action) => {
      const { course, mediaEvaluation, result, totalRecords } = action.payload;
      state.currentCourseEvaluation = {
        course,
        mediaEvaluation,
        result: result.reverse(),
      };
      state.totalCount = totalRecords;
      state.error = null;
      state.listLoading = false;
    },
    // -------------------------------------------------------------------------------
    classViewed: (state, action) => {
      const { classId } = action.payload;
      let classIndex = -1;
      let sectionIndex = -1;

      for (let i = 0; i < state.sections?.length; i++) {
        let targetClass = state.sections[i].course_class_id.findIndex(
          (courseClass) => courseClass.id === classId
        );

        if (targetClass !== -1) {
          sectionIndex = i;
          classIndex = targetClass;
        }
      }

      state.sections[sectionIndex].course_class_id[
        classIndex
      ].course_class_id[0] = {
        viewed: true,
      };

      state.error = null;
      state.actionsLoading = false;
    },

    setTests(state, action) {
      state.tests = action.payload;
      state.error = null;
      state.listLoading = false;
    },

    setAttempt(state, action) {
      state.attempt = action.payload;
      state.error = null;
      state.listLoading = false;
    },

    clear(state, action) {
      state.currentCourse = undefined;
      state.tests = undefined;
      state.attempt = undefined;
      state.exam = undefined;
      state.currentCourseEvaluation = undefined;
      state.sections = undefined;
      state.isManagement = undefined;
    },
  },
});
