import { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

import { injectIntl } from "react-intl";
import * as actions from "./_redux/home/homeActions";

import { Favorite, Settings } from "@material-ui/icons";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import api from "../services/api";
import BookmarkContentCard from "./components/BookmarksContentCard";
import ProductBookmarkModal from "./components/ProductBookmarkModal";
import RequestsCards from "./components/RequestsCard";

import { useSnackbar } from "notistack";
import { Can } from "../routes/Can";
import BannersCarousel from "./components/BannersCarousel";
import BookmarksByProducts from "./components/BookmarksByProducts";

import * as bannersActions from "../modules/System/_redux/banners/bannersActions";
import LatestNewsletters from "./components/LatestNewsletters";

const {
  REACT_APP_BASE_URL,
  REACT_APP_ORPEN_SERVER_URL,
  REACT_APP_HAS_WEBCHAT,
} = process.env;

const Home = ({ intl }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayAlert, setDisplayAlert] = useState(false);
  const [currentAlert, setCurrentAlert] = useState({
    title: "",
    content: "",
    link: "",
  });
  const [openModal, setOpenModal] = useState(false);

  const { currentState, publicBanners, auth } = useSelector(
    (state) => ({
      currentState: state.home,
      publicBanners: state.banner.publics,
      auth: state.auth,
    }),
    shallowEqual
  );

  const {
    lastUserViews,
    totalViews,
    availableRequests,
    notificationsUnreceived,
    latestNews,
    latestMovies,
    pickUpRecentNewsletter,
  } = currentState;

  const { roles, user } = auth;
  const canApprove = roles.filter((role) => role === "system-users:approve");
  const canWebchat = roles.filter((role) => role === "webchat:view");
  const bookmarksContent = roles.filter(
    (role) => role === "home:bookmarks-content:view"
  );
  const bookmarksProducts = roles.filter(
    (role) => role === "home:bookmarks-products:view"
  );
  const userContentViews = roles.filter(
    (role) => role === "home:user-content-views:view"
  );
  const systemContentViews = roles.filter(
    (role) => role === "home:system-content-views:view"
  );
  const lastVideos = roles.filter((role) => role === "home:last-videos:view");
  const lastUpdates = roles.filter((role) => role === "home:last-updates:view");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchRoles());
    dispatch(actions.fetchProducts());
    dispatch(actions.fetchAvailableRequests());
    dispatch(actions.fetchLatestNews(1, 5));
    dispatch(actions.fetchLatestMovies(1, 5));
    dispatch(actions.fetchDashboardStatistics());
    dispatch(actions.fetchLanguage(user.id));
  }, []);

  useEffect(() => {
    if (canWebchat.length > 0) {
      api.get("/settings").then((response) => {
        const settings = response.data;
        if (Array.isArray(settings)) {
          const webchat = settings.find(
            (config) => config.name === "hash_webchat"
          );
          if (webchat) {
            const webchatContent = JSON.parse(webchat.content ?? "{}");

            if (webchatContent.status) {
              installOrpenWebChat(
                webchatContent.hash_webchat,
                webchatContent.uri
              );
            }
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (notificationsUnreceived.length > 0) {
      handleAlerts(notificationsUnreceived);
    }
  }, [notificationsUnreceived]);

  const handleAlerts = (alerts) => {
    const alertsIds = alerts.map((alert) => alert.id);
    const warnings = alerts.filter((alert) => alert.type === "WARNING");

    if (warnings.length > 0) {
      warnings.forEach((warning) => {
        let alertType = "info";
        if (warning.priority === "NORMAL") {
          alertType = "warning";
        } else if (warning.priority === "HIGH") {
          alertType = "error";
        }

        enqueueSnackbar(`${warning.title ? warning.title : "Atenção"}`, {
          variant: alertType,
          persist: true,
          action: (key) => (
            <Fragment>
              <Button
                onClick={() => {
                  closeSnackbar(key);
                  handleReadNotification(warning.id);
                }}
                variant="default"
              >
                {intl.formatMessage({ id: "PAGE.HOME.ALERTS_VIEW" })}
              </Button>
            </Fragment>
          ),
        });
      });
    }

    if (alertsIds.length > 0) {
      dispatch(actions.receivedNotifications(alertsIds));
    }
  };

  const handleReadNotification = (id) => {
    dispatch(actions.getNotification(id))
      .then((response) => {
        const notification = response.data;
        setCurrentAlert({
          title: notification.notification_id.title,
          content: notification.notification_id.content,
          link: notification.notification_id.link,
        });
        setDisplayAlert(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseDisplayAlert = () => {
    setDisplayAlert(false);
  };

  const getNoImgeByType = (knowledgeType) => {
    switch (knowledgeType) {
      case "faq":
        return "Books-14_2-color.svg";
      case "video":
        return "Communication-25_2-color.svg";
      case "link":
        return "UIExpressive_15_2-color.svg";
      case "manual":
        return "Books-07_2-color.svg";
      case "news":
        return "Clouds-03_2-color.svg";
      default:
        return "Books-14_2-color.svg";
    }
  };

  const installOrpenWebChat = (entranceHash, orpenServerUrl) => {
    window._owc = window._owc || [];
    window._owc.push(["_setOwcChannelHash", entranceHash]);
    window._owc.push(["_setOwcChannelUCI", auth.user.email]);
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.id = "orpenwebchat";
    s.async = true;
    s.src = `${orpenServerUrl}/webchat/sdk/owc.js?ver=1.1`;
    const b = document.getElementsByTagName("script")[0];
    b.parentNode.insertBefore(s, b);
  };

  const nl2br = (str, is_xhtml) => {
    if (typeof str === "undefined" || str === null) {
      return "";
    }
    var breakTag =
      is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
    return (str + "").replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      "$1" + breakTag + "$2"
    );
  };

  useEffect(() => {
    dispatch(bannersActions.getPublicBanners());
  }, [dispatch]);

  return (
    <>
      {canApprove.length > 0 ? (
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <RequestsCards
              availableRequests={availableRequests}
              className="card-stretch gutter-b"
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {publicBanners?.length > 0 && (
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <BannersCarousel />
          </div>
        </div>
      )}

      {pickUpRecentNewsletter?.length > 0 && (
        <div className="row">
          <div className="col-lg-12 col-xxl-12">
            <LatestNewsletters />
          </div>
        </div>
      )}

      <div className="row">
        {bookmarksContent.length > 0 ? (
          <div className="col-md-4 d-flex">
            <Card style={{ flex: "auto" }}>
              <CardHeader>
                <CardHeaderTitle className="d-flex align-items-center font-size-md font-weight-bolder">
                  {intl.formatMessage({
                    id: "PAGE.HOME.BOOKMARK_CONTENT.TITLE",
                  })}
                </CardHeaderTitle>
                <CardHeaderToolbar>
                  <Favorite className="text-danger" fontSize="large" />
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <BookmarkContentCard />
              </CardBody>
            </Card>
          </div>
        ) : (
          <></>
        )}

        {userContentViews.length > 0 ? (
          <div className="col-md-4 d-flex">
            <Card style={{ flex: "auto" }}>
              <CardHeader>
                <CardHeaderTitle className="d-flex align-items-center font-weight-bolder">
                  {intl.formatMessage({ id: "PAGE.HOME.LEFT_CARD" })}
                </CardHeaderTitle>
              </CardHeader>
              <CardBody>
                {lastUserViews.map((knowledge, index) => (
                  <div
                    key={knowledge.id + index}
                    className="d-flex align-items-center p-5 mb-5"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                  >
                    <div className="symbol symbol-60 symbol-light mr-5">
                      <span
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${REACT_APP_BASE_URL}/knowledge/${
                            knowledge.icon
                          }), url(${toAbsoluteUrl(
                            `/media/svg/icons/Thomson/${getNoImgeByType(
                              knowledge.name
                            )}`
                          )})`,
                        }}
                      ></span>
                    </div>
                    <div
                      style={{ overflow: "auto" }}
                      className="d-flex flex-column flex-grow-1 font-weight-bolder"
                    >
                      <a
                        href={`/knowledge-base/view/${knowledge.id}`}
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {knowledge.title}
                      </a>
                      <span className="text-muted">{knowledge.keywords}</span>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          </div>
        ) : (
          <></>
        )}

        {systemContentViews.length > 0 ? (
          <div className="col-md-4 d-flex">
            <Card style={{ flex: "auto" }}>
              <CardHeader>
                <CardHeaderTitle className="d-flex align-items-center font-weight-bolder">
                  {intl.formatMessage({ id: "PAGE.HOME.RIGHT_CARD" })}
                </CardHeaderTitle>
              </CardHeader>
              <CardBody>
                {totalViews.map((knowledge) => (
                  <div
                    key={knowledge.id}
                    className="d-flex align-items-center p-5 mb-5"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                  >
                    <div className="symbol symbol-60 symbol-light mr-5">
                      <span
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${REACT_APP_BASE_URL}/knowledge/${
                            knowledge.icon
                          }), url(${toAbsoluteUrl(
                            `/media/svg/icons/Thomson/${getNoImgeByType(
                              knowledge.type
                            )}`
                          )})`,
                        }}
                      ></span>
                    </div>
                    <div
                      style={{ overflow: "auto" }}
                      className="d-flex flex-column flex-grow-1 font-weight-bolder"
                    >
                      <a
                        href={`/knowledge-base/view/${knowledge.id}`}
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {knowledge.title}
                      </a>
                      <span className="text-muted">{knowledge.keywords}</span>
                    </div>
                    <Can
                      perform="dashboard-total-knowledge"
                      yes={() => {
                        return (
                          <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                            {knowledge.total}
                          </span>
                        );
                      }}
                      no={() => {
                        return null;
                      }}
                    />
                  </div>
                ))}
              </CardBody>
            </Card>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="row">
        {bookmarksProducts.length > 0 ? (
          <div className="col-md-4 d-flex">
            <Card style={{ flex: "auto" }}>
              <CardHeader>
                <CardHeaderTitle className="d-flex align-items-center font-weight-bolder">
                  {intl.formatMessage({
                    id: "PAGE.HOME.BOOKMARK_BY_TYPE.TITLE",
                  })}
                </CardHeaderTitle>
                <CardHeaderToolbar>
                  <Favorite className="text-danger" fontSize="large" />
                  <Button
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    variant="none p-0 ml-2"
                  >
                    <Settings className="text-hover-primary" fontSize="large" />
                  </Button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <BookmarksByProducts openModal={openModal} />
              </CardBody>
            </Card>
          </div>
        ) : (
          <></>
        )}

        {lastVideos.length > 0 ? (
          <div className="col-md-4 d-flex">
            <Card style={{ flex: "auto" }}>
              <CardHeader>
                <CardHeaderTitle className="d-flex align-items-center font-size-md font-weight-bolder">
                  {intl.formatMessage({ id: "PAGE.HOME.LAST_MOVIES" })}
                </CardHeaderTitle>
              </CardHeader>
              <CardBody>
                {latestMovies.map((knowledge, index) => (
                  <div
                    key={knowledge.id + index}
                    className="d-flex align-items-center p-5 mb-5"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                  >
                    <div className="symbol symbol-60 symbol-light mr-5">
                      <span
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${REACT_APP_BASE_URL}/knowledge/${
                            knowledge.icon
                          }), url(${toAbsoluteUrl(
                            "/media/svg/icons/Thomson/Communication-25_2-color.svg"
                          )})`,
                        }}
                      ></span>
                    </div>
                    <div
                      style={{ overflow: "auto" }}
                      className="d-flex flex-column flex-grow-1 font-weight-bolder"
                    >
                      <a
                        href={`/knowledge-base/view/${knowledge.id}`}
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {knowledge.title}
                      </a>
                      <span className="text-muted">{knowledge.keywords}</span>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          </div>
        ) : (
          <></>
        )}

        {lastUpdates.length > 0 ? (
          <div className="col-md-4 d-flex">
            <Card style={{ flex: "auto" }}>
              <CardHeader>
                <CardHeaderTitle className="d-flex align-items-center font-size-md font-weight-bolder">
                  {intl.formatMessage({ id: "PAGE.HOME.LAST_NEWS" })}
                </CardHeaderTitle>
              </CardHeader>
              <CardBody>
                {latestNews.map((knowledge, index) => (
                  <div
                    key={knowledge.id + index}
                    className="d-flex align-items-center p-5 mb-5"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                  >
                    <div className="symbol symbol-60 symbol-light mr-5">
                      <span
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${REACT_APP_BASE_URL}/knowledge/${
                            knowledge.icon
                          }), url(${toAbsoluteUrl(
                            "/media/svg/icons/Thomson/Clouds-03_2-color.svg"
                          )})`,
                        }}
                      ></span>
                    </div>
                    <div
                      style={{ overflow: "auto" }}
                      className="d-flex flex-column flex-grow-1 font-weight-bolder"
                    >
                      <a
                        href={`/knowledge-base/view/${knowledge.id}`}
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        {knowledge.title}
                      </a>
                      <span className="text-muted">{knowledge.keywords}</span>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          </div>
        ) : (
          <></>
        )}
      </div>

      <ProductBookmarkModal openModal={openModal} setOpenModal={setOpenModal} />

      <Modal size="lg" show={displayAlert} onHide={handleCloseDisplayAlert}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentAlert.title
              ? currentAlert.title
              : intl.formatMessage({ id: "PAGE.HOME.ALERTS_TITLE" })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {currentAlert?.content} */}
          <div
            dangerouslySetInnerHTML={{
              __html: `${nl2br(currentAlert?.content)}`,
            }}
          ></div>
          {currentAlert.link ? (
            <>
              <div className="separator separator-dashed my-7" />
              <a
                href={`currentAlert.link`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-icon btn-light btn-hover-primary btn-sm"
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}
                  />
                </span>
              </a>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDisplayAlert}>
            {intl.formatMessage({ id: "SYSTEM.BUTTONS.CLOSE" })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default injectIntl(Home);
