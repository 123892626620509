import React from "react";

import { Favorite } from "@material-ui/icons";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const Bookmark = ({
  handleSaveBookmark,
  handleDeleteBookmark,
  type,
  id,
  bookmark,
  size,
  tooltip,
}) => {
  const verifyTypes = (type) => {
    switch (type) {
      case "knowledge":
        return { knowledge_id: id };
      case "course":
        return { course_id: id };
      case "product":
        return { system_product_id: id };

      default:
        return { knowledge_type_id: id };
    }
  };

  const handleBookmarkPost = () => {
    handleSaveBookmark(verifyTypes(type));
  };
  const handleBookmarkDelete = () => {
    handleDeleteBookmark(bookmark, id);
  };

  return (
    <>
      {tooltip === "none" ? (
        <Button
          variant="none"
          className={`${bookmark === null ? "text-muted" : "text-danger"} p-0`}
          onClick={() => {
            bookmark === null ? handleBookmarkPost() : handleBookmarkDelete();
          }}
        >
          <Favorite fontSize={size ?? "large"} />
        </Button>
      ) : (
        <OverlayTrigger
          placement={tooltip ?? "auto"}
          overlay={
            <Tooltip id="quick-panel-tooltip">
              <FormattedMessage id="COMPONENT.BOOKMARK.TOOLTIP" />
            </Tooltip>
          }
        >
          <Button
            variant="none"
            className={`${
              bookmark === null ? "text-muted" : "text-danger"
            } p-0`}
            onClick={() => {
              bookmark === null ? handleBookmarkPost() : handleBookmarkDelete();
            }}
          >
            <Favorite fontSize={size ?? "large"} />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};

export default Bookmark;
