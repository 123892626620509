/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useMemo, useEffect} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {Nav, Tab, Dropdown} from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../../_helpers";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import { FormattedMessage } from "react-intl";
import UserWarningList from './UserWarningList';
import UserNotificationsList from './UserNotificationsList';
import Pagination from "react-js-pagination";
import { Modal, Button } from "react-bootstrap";
import * as actions from '../../../../../app/pages/_redux/home/homeActions';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

export function UserNotificationsDropdown() {
  const [displayAlert, setDisplayAlert] = useState(false);
  const [currentAlert, setCurrentAlert] = useState({ title: "", content: "", link: "" });
  const [currentPageWarningNumber, setCurrentPageWarningNumber] = useState(1);
  const [currentPageNotificationNumber, setCurrentPageNotificationNumber] = useState(1);
  const [unreadAlerts, setUnreadAlerts] = useState(0);

  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.notifications.layout") === "offcanvas",
    };
  }, [uiService]);

  const { entitiesWarnings, entitiesNotifications, totalCountWarning, totalCountNotifications, notificationUnreadCountByType } = useSelector(
    (state) => ({
      entitiesWarnings: state.home.warningsEntities,
      entitiesNotifications: state.home.notificationsEntities,
      totalCountWarning: state.home.warningsEntitiesTotalCount,
      totalCountNotifications: state.home.notificationsEntitiesTotalCount,
      notificationUnreadCountByType: state.home.notificationUnreadCountByType
    }),
    shallowEqual
  );

  const notificationsUnread = notificationUnreadCountByType.find((notification) => (notification.type === "NOTIFICATION"));
  const warningsUnread = notificationUnreadCountByType.find((notification) => (notification.type === "WARNING"));

  const pageChangeNotification = (newPageNumber) => {
    setCurrentPageNotificationNumber(newPageNumber);
  };

  const pageChangeWarning = (newPageNumber) => {
    setCurrentPageWarningNumber(newPageNumber);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchWarnings(currentPageWarningNumber, 10));
  }, [dispatch, currentPageWarningNumber]);

  useEffect(() => {
    dispatch(actions.fetchNotifications(currentPageNotificationNumber, 10));
  }, [dispatch, currentPageNotificationNumber]);


  useEffect(() => {
    hanglePulseClass();
  }, [notificationUnreadCountByType]);

  

  const hanglePulseClass = () => {
    let total = 0;
    if(notificationsUnread) {
      total = parseInt(notificationsUnread.total);
    }

    if(warningsUnread) {
      total += parseInt(warningsUnread.total);
    }

    setUnreadAlerts(total);
  };

  const handleAlertsToggle = (open) => {
    if(open === true) {
      dispatch(actions.fetchWarnings(currentPageWarningNumber, 10));
      dispatch(actions.fetchNotifications(currentPageNotificationNumber, 10));
    }
  };

  const handleReadNotification = (id) => {
    dispatch(actions.getNotification(id)).then((response) => {
      const notification = response.data;
      setCurrentAlert({
        title: notification.notification_id.title,
        content: notification.notification_id.content,
        link: notification.notification_id.link,
      });
      setDisplayAlert(true);
      dispatch(actions.fetchDashboardStatistics());
      dispatch(actions.fetchWarnings(currentPageWarningNumber, 10));
      dispatch(actions.fetchNotifications(currentPageNotificationNumber, 10));
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleCloseDisplayAlert = () => {
    setDisplayAlert(false);
  }

  const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  return (<>
        {layoutProps.offcanvas && (<div className="topbar-item">
          <div className={`btn btn-icon btn-clean btn-lg mr-1 ${unreadAlerts > 0 ? 'pulse' : ''} pulse-primary`} id="kt_quick_notifications_toggle">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")}/>
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>)}
        {!layoutProps.offcanvas && (<Dropdown onToggle={handleAlertsToggle} drop="down" alignRight>
          <Dropdown.Toggle
              as={DropdownTopbarItemToggler}
              id="kt_quick_notifications_toggle"
          >
            <div className={`btn btn-icon btn-clean btn-lg mr-1 ${unreadAlerts > 0 ? 'pulse' : ''} pulse-primary`} id="kt_quick_notifications_toggle">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")}/>
                </span>
              <span className="pulse-ring"></span>
              <span className="pulse-ring"/>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                  className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top" style={{backgroundImage: `url(${bgImage})`}}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white"><FormattedMessage id="HOME.ALERTS.WARNING.PENDING" /></span>
                  <span className="btn btn-text btn-warning btn-sm font-weight-bold btn-font-md ml-2">
                    {warningsUnread ? warningsUnread.total : 0}
                  </span>
                </h4>

                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white"><FormattedMessage id="HOME.ALERTS.NOTIFICATIONS.PENDING" /></span>
                  <span className="btn btn-text btn-info btn-sm font-weight-bold btn-font-md ml-2">
                    {notificationsUnread ? notificationsUnread.total : 0}
                  </span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                      as="ul"
                      className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                      onSelect={_key => setKey(_key)}
                  >
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                          eventKey="Alerts"
                          className={`nav-link show ${
                              key === "Alerts" ? "active" : ""
                          }`}
                      >
                        <FormattedMessage id="HOME.ALERTS.WARNING" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                          eventKey="Events"
                          className={`nav-link show ${
                              key === "Events" ? "active" : ""
                          }`}
                      >
                        <FormattedMessage id="HOME.ALERTS.NOTIFICATIONS" />
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" className="p-2">
                      <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          className="navi navi-hover scroll mb-4 px-2"
                          style={{ maxHeight: "300px", position: "relative" }}
                      >
                        {entitiesWarnings.length <= 0 ?
                          <div className="fade alert alert-secondary show" style={{ textAlign: "center" }}>
                            <FormattedMessage id="PAGE.ALERTS.EMPTY.WARNING" />
                          </div>
                        :
                          <UserWarningList entities={entitiesWarnings} handleReadNotification={handleReadNotification} />
                        }
                      </PerfectScrollbar>
                      <Pagination
                        innerClass="pagination pagination-md d-flex justify-content-center"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={currentPageWarningNumber}
                        itemsCountPerPage={10}
                        totalItemsCount={parseInt(totalCountWarning)}
                        pageRangeDisplayed={5}
                        onChange={pageChangeWarning}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Events" id="topbar_notifications_events" className="p-2">
                      <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          className="navi navi-hover scroll mb-4 px-2"
                          style={{ maxHeight: "300px", position: "relative" }}
                      >
                        {entitiesNotifications.length <= 0 ?
                          <div className="fade alert alert-secondary show" style={{ textAlign: "center" }}>
                            <FormattedMessage id="PAGE.ALERTS.EMPTY.NOTIFICATIONS" />
                          </div>
                        :
                          <UserNotificationsList entities={entitiesNotifications} handleReadNotification={handleReadNotification} />
                        }
                      </PerfectScrollbar>
                      <Pagination
                        innerClass="pagination pagination-md d-flex justify-content-center"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={currentPageNotificationNumber}
                        itemsCountPerPage={10}
                        totalItemsCount={parseInt(totalCountNotifications)}
                        pageRangeDisplayed={5}
                        onChange={pageChangeNotification}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>)}
        <Modal size="lg" show={displayAlert} onHide={handleCloseDisplayAlert}>
          <Modal.Header closeButton>
            <Modal.Title>
              {currentAlert.title ? currentAlert.title : 'Alerta'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {currentAlert?.content} */}
            <div
            dangerouslySetInnerHTML={{
              __html: `${nl2br(currentAlert?.content)}`,
            }}
          ></div>
            {currentAlert.link ?
              <>
                <div className="separator separator-dashed my-7"/>
                 <a href={`currentAlert.link`} target="_blank" rel="noopener noreferrer" class="btn btn-icon btn-light btn-hover-primary btn-sm">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")} />
                  </span>
                </a>
              </>
              : <></>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDisplayAlert}>
              <FormattedMessage id="SYSTEM.BUTTONS.CLOSE" />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
}
