import { createSlice } from "@reduxjs/toolkit";

const initialAccomplishmentState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  accomplishmentUrl: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const accomplishmentSlice = createSlice({
  name: "accomplishment",
  initialState: initialAccomplishmentState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      }
    },
    accomplishmentFetched: (state, action) => {
      const { certificateUrl } = action.payload;
      state.accomplishmentUrl = certificateUrl;
      state.error = null;
      state.latestNewsLoading = false;
    },
  },
});
