// const roles = [
//   "home:view",

//   "knowledge:get",
//   "knowledge-management:get",
//   "knowledge-management-dashboard:get",
//   "knowledge-management-knowledge:get",
//   "knowledge-faqs:get",
//   "knowledge-links:get",
//   "knowledge-manuals:get",
//   "knowledge-videos:get",
//   "knowledge-news:get",

//   "learning:get",
//   "learning-management:get",
//   "learning-management-dashboard:get",
//   "learning-management-courses:get",
//   "learning-management-tests:get",
//   "learning-management-certs:get",
//   "learning-repository:get",
//   "learning-mycourses:get",
//   "learning-mycerts:get",

//   "system:get",
//   "system-users:get",
//   "system-settings:get",
//   "system-assets:get",
//   "system-reports:get",

//   "ecommerce:get",
// ];

export const rolesConfig = {
  HOME: {
    INDEX: "home:view",
  },
  ALERTS: {
    INDEX: "alerts:view",
  },
  KNOWLEDGE: {
    INDEX: "knowledge:view",
    MANAGEMENT: {
      INDEX: "knowledge-management:view",
      DASHBOARD: "knowledge-management-dashboard:view",
      CONTENT: "knowledge-management-content:view",
    },
    FAQS: "knowledge-faqs:view",
    LINKS: "knowledge-links:view",
    MANUALS: "knowledge-manuals:view",
    VIDEOS: "knowledge-videos:view",
    NEWS: "knowledge-news:view",
    NEWSLETTER: "knowledge-newsletter:view",
  },
  LEARNING: {
    INDEX: "learning:view",
    MANAGEMENT: {
      INDEX: "learning-management:view",
      DASHBOARD: "learning-management-dashboard:view",
      COURSE: "learning-management-courses:view",
      COURSE_SECTION: "learning-management-section-course:view",
      TESTS: "learning-management-tests:view",
      CERTS: "learning-management-certs:view",
    },
    REPOSITORY: "learning-repository:view",
    MYCOURSES: "learning-mycourses:view",
    MYCERTS: "learning-mycerts:view",
  },
  SYSTEM: {
    INDEX: "system:view",
    USERS: "system-users:view",
    SETTINGS: "system-settings:view",
    ASSETS: "system-assets:view",
    REPORTS: "system-reports:view",
    TEMPLATES: "system:template:certified",
  },
  ECOMMERCE: {
    INDEX: "ecommerce:view",
  },
};
