import * as Yup from "yup";

export const ApprovalValidationSchema = (intl, blockProducts) => {
  return Yup.object().shape({
        role_id: Yup.object()
            .required(intl.formatMessage({id: "HOME.REQUESTS.APPROVE.VALIDATION.ROLE_ID"})),
        products: blockProducts ? Yup.array() : Yup.array().min(1, intl.formatMessage({id: "HOME.REQUESTS.APPROVE.VALIDATION.PRODUCTS"})),
        identifier: Yup.string()
    });
};
