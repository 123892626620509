/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";

import { FormattedMessage } from "react-intl";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Pagination from "react-js-pagination";
import Select from "react-select";

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

import * as actions from "../../../_redux/home/homeActions";

import { useBookmarkUIContext } from "../BookmarkUIContext";

export function BookmarksResult({ data }) {
  const { REACT_APP_BASE_URL } = process.env;

  const bookmarkUIContext = useBookmarkUIContext();
  const bookmarkUIProps = useMemo(() => {
    return {
      queryParams: bookmarkUIContext.queryParams,
      setQueryParams: bookmarkUIContext.setQueryParams,
    };
  }, [bookmarkUIContext]);

  const { bookmarks, filter, totalCount, listLoading } = useSelector(
    (state) => ({
      bookmarks: state.home.bookmarks,
      filter: state.home.filter,
      listLoading: state.home.listLoading,
      totalCount: state.home.totalCount,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchCurrentBookmarksContent(bookmarkUIProps.queryParams));
  }, [dispatch, bookmarkUIProps.queryParams]);

  const pageChange = (newPageSize, newPageNumber) => {
    bookmarkUIProps.setQueryParams((prev) => ({
      ...prev,
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    }));

    // dispatch(actions.fetchCurrentBookmarksContent(bookmarkUIProps.queryParams));

    dispatch(actions.updatePagination(newPageSize, newPageNumber));
  };

  if (bookmarks?.length === 0) {
    return (
      <div style={{ height: "100%" }}>
        <div className="text-muted">
          <FormattedMessage id="GENERAL_SEARCH.EMPTY_DATA.MESSAGE" />
        </div>
      </div>
    );
  }

  const returnIcon = (icon, type) => {
    if (type === "knowledge") {
      return `url(${REACT_APP_BASE_URL}/knowledge/${icon})`;
    }
    return `url(${REACT_APP_BASE_URL}/course/${icon})`;
  };

  const customStyles = {
    control: () => ({
      width: 60,
      height: "31px",
      display: "flex",
      border: "1px solid #ebedf3",
      borderRadius: "0.42rem",
    }),
    dropdownIndicator: () => ({
      padding: 1,
    }),
  };
  const paginationSize = [
    {
      value: 5,
      label: "5",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  const getNoImgeByType = (knowledgeType, contentType) => {
    if (contentType !== "course") {
      switch (knowledgeType) {
        case 1:
        case "faq":
          return "Books-14_2-color.svg";
        case 2:
        case "video":
          return "Communication-25_2-color.svg";
        case 5:
        case "link":
          return "UIExpressive_15_2-color.svg";
        case 3:
        case "manual":
          return "Books-07_2-color.svg";
        case 4:
        case "news":
          return "Clouds-03_2-color.svg";
        default:
          return "Books-14_2-color.svg";
      }
    } else {
      return "Books-01_2-color.svg";
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="mb-auto">
        {bookmarks.map((item, index) => (
          <div
            key={item.item_id}
            className="d-flex align-items-center p-5 mb-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div className="symbol symbol-60 symbol-light mr-5">
              <span
                className="symbol-label"
                style={{
                  backgroundImage: `${returnIcon(
                    item.icon,
                    item.type
                  )}, url(${toAbsoluteUrl(
                    `/media/svg/icons/Thomson/${getNoImgeByType(
                      item.type_id,
                      item.type
                    )}`
                  )}`,
                }}
              ></span>
            </div>
            <div
              style={{ overflow: "auto" }}
              className="d-flex flex-column flex-grow-1 font-weight-bolder"
            >
              <a
                href={
                  item.type === "knowledge"
                    ? `/knowledge-base/view/${item.item_id}`
                    : `/learning/mycourses/${item.item_id}/view/class`
                }
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                {item.name}
              </a>

              <span className="text-muted">{item.keywords}</span>
            </div>
          </div>
        ))}
      </div>
      <div
        className="row"
        style={{ position: "absolute", bottom: 20, right: 35 }}
      >
        <>
          <Pagination
            isLoading={listLoading}
            innerClass="pagination my-auto pagination-md"
            itemClass="page-item"
            linkClass="page-link"
            activePage={filter.pageNumber}
            itemsCountPerPage={5}
            totalItemsCount={parseInt(totalCount)}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => {
              pageChange(5, pageNumber);
            }}
          />
        </>
      </div>
    </div>
  );
}
