import { createSlice } from "@reduxjs/toolkit";

const initialCertificateState = {
  listLoading: false,
  actionsLoading: false,
  certificateUrl: null,
  certificate_id: undefined,
  courseName: undefined,
  created_at: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const certificateSlice = createSlice({
  name: "certificate",
  initialState: initialCertificateState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    certificateFetched: (state, { payload }) => {
      const {
        certificateUrl,
        certificate,
        courseName,
        created_at,
        courseId,
      } = payload;
      state.certificateUrl = certificateUrl;
      state.certificate_id = certificate;
      state.courseName = courseName;
      state.courseId = courseId;
      state.created_at = created_at;
      state.actionsLoading = false;
      state.error = null;
    },
    clear: (state, { payload }) => {
      state.certificateUrl = undefined;
      state.certificate_id = undefined;
      state.courseName = undefined;
      state.courseId = undefined;
      state.created_at = undefined;

      state.actionsLoading = false;
      state.error = null;
    },
  },
});
