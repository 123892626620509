import { createSlice } from "@reduxjs/toolkit";

const initialContentsState = {
  listLoading: false,
  actionsLoading: false,
  settingsAppearance: [],
  settingsEmail: [],
  settingsIntegrations: [],
  settingsEvaluation: [],
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialContentsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    settingsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      const settingsAppearance = entities.filter(
        (config) => config.subject === "appearance"
      );
      state.settingsAppearance = settingsAppearance.reduce((group, config) => {
        group[config.name] = config.content;
        return group;
      }, {});
      const settingsEmail = entities.filter(
        (config) => config.subject === "email"
      );
      state.settingsEmail = settingsEmail.reduce((group, config) => {
        group[config.name] = config.content;
        return group;
      }, {});
      const settingsIntegrations = entities.filter(
        (config) => config.subject === "integrations"
      );
      state.settingsIntegrations = settingsIntegrations.reduce(
        (group, config) => {
          group[config.name] = config.content;
          return group;
        },
        {}
      );
      const settingsEvaluation = entities.filter(
        (config) => config.subject === "evaluation"
      );
      state.settingsEvaluation = settingsEvaluation.reduce((group, config) => {
        group[config.name] = config.content;
        return group;
      }, {});
    },
  },
});
