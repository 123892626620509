import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";

export function Brand() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  const systemLogo = localStorage.getItem('system_logo');

  const systemFavicon = localStorage.getItem('system_favicon');
  if(systemFavicon) {
    const favicon = document.getElementById("system_favicon");
    favicon.href = systemFavicon;
  }

  return (
    <>
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        <Link to="" className="brand-logo">
          <img style={{maxWidth: "170px"}} alt="logo" src={systemLogo ? systemLogo : layoutProps.headerLogo} />
        </Link>

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-double-left.svg"
                  )}
                />
              </span>
            </button>
          </>
        )}
      </div>
    </>
  );
}
