import api from "../../../../services/api";

export const BANNERS = "/banner";
export const ASSETS_FIND_URL = "/products/find";
export const ASSETS_SUB_FIND_URL = "/products";
export const ASSETS_GET_URL = "/products";

export function getBanner(id) {
  return api.get(BANNERS + "/" + id);
}

export function getBanners() {
  return api.get(BANNERS + "/find");
}
export function getPublicBanners() {
  return api.get(BANNERS);
}

export function createBanner(banner) {
  return api.post(BANNERS, banner);
}

export function updateBanner(id, banner) {
  return api.patch(`${BANNERS}/${id}`, banner);
}

export function deleteBanner(id) {
  return api.delete(`${BANNERS}/${id}`);
}
export function changeVisibility(id) {
  return api.patch(`${BANNERS}/active/${id}`);
}

export function reorder(positions) {
  return api.post(`${BANNERS}/reorder`, positions);
}

export function registerClick(bannerId) {
  return api.post(`${BANNERS}/view/${bannerId}`);
}
