import { getConfig, setLanguage } from "../../../../_metronic/i18n";
import * as requestFromServer from "./homeCrud";
import { homeSlice, callTypes } from "./homeSlice";

const { actions } = homeSlice;

export const fetchDashboardStatistics = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getHomeStatistics();
    dispatch(
      actions.statisticsFetched({
        lastUserViews: response.data.lastUserViews,
        totalViews: response.data.lastViewsOnSystem,
        showBookmarksByType: response.data.bookmarksByType,
        notificationUnreadCountByType:
          response.data.notificationUnreadCountByType,
        notificationsUnreceived: response.data.notificationsUnreceived,
        pickUpRecentNewsletter: response.data.pickUpRecentNewsletter,
      })
    );
  } catch (error) {
    error.clientMessage = "Can't find statistics";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};
export const fetchBookmarksByProducts = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getBookmarksByProducts({
      searchText: queryParams.searchText,
      pageNumber: !!queryParams.searchText
        ? 0
        : parseInt(queryParams.pageNumber) - 1,
      pageSize: queryParams.pageSize,
    });
    dispatch(
      actions.bookmarksByProductsFetched({
        showBookmarksByProducts: response.data.bookmarksByProduct,
        totalCount: response.data.totalRecords,
      })
    );
  } catch (error) {
    error.clientMessage = "Can't fetch bookmarks by products";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const updatePagination = (pageSize, pageNumber) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.paginationChanged({ pageSize, pageNumber }));
};
export const updatePaginationBookmarksByProduct = (
  pageSize,
  pageNumber
) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.paginationBookmarksByProduct({ pageSize, pageNumber }));
};

export const fetchCurrentBookmarksContent = (queryParams) => async (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getBookmarksContent({
      filter: queryParams.filter,
      searchText: queryParams.searchText,
      sortOrder: queryParams.sortOrder.toUpperCase(),
      sortField: queryParams.sortField,
      pageNumber: !!queryParams.searchText
        ? 0
        : parseInt(queryParams.pageNumber) - 1,
      pageSize: queryParams.pageSize,
    });
    dispatch(
      actions.bookmarksContentFetched({
        bookmarks: response.data.bookmakrs,
        totalCount: response.data.totalRecords,
      })
    );
  } catch (error) {
    error.clientMessage = "Can't find bookmarks";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchAvailableRequests = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getPendingRequests();
    dispatch(actions.requestsFetched({ availableRequests: response.data }));
  } catch (error) {
    error.clientMessage = "Can't find statistics";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchProducts = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllProducts()
    .then((response) => {
      dispatch(actions.productsFetched({ products: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUserProducts = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserProducts()
    .then((response) => {
      dispatch(
        actions.userProductsFetched({ userProducts: response.data.items })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRoles = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllRoles()
    .then((response) => {
      dispatch(actions.rolesFetched({ roles: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find roles";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const approveUser = (userId, requestBody) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .approvalRequest(userId, requestBody)
    .then((response) => {
      dispatch(actions.userApproved());
    })
    .catch((error) => {
      error.clientMessage = "Can't approve user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const reproveUser = (userId, requestBody) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .denyRequest(userId, requestBody)
    .then((response) => {
      dispatch(actions.userApproved());
    })
    .catch((error) => {
      error.clientMessage = "Can't deny user";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const pushBookmark = (requestBody) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.postBookmark(requestBody);
    dispatch(
      actions.bookmarkPost({
        bookmark: response.data,
        system_product_id: requestBody.system_product_id,
      })
    );
  } catch (error) {
    error.clientMessage = "Can't post bookmark";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const removeBookmark = (id, system_product_id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.deleteBookmark(id);
    dispatch(actions.bookmarkDeleted({ system_product_id }));
  } catch (error) {
    error.clientMessage = "Can't delete bookmark";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const receivedNotifications = (notifications) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .receivedNotifications(notifications)
    .then((response) => {
      dispatch(actions.receivedNotificationsChecked());
    })
    .catch((error) => {
      error.clientMessage = "Can't update notification received";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchWarnings = (pageNumber, pageSize) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.warningsList }));
  return requestFromServer
    .findWarnings({
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
      pageNumber: parseInt(pageNumber) - 1,
      pageSize: pageSize,
    })
    .then((response) => {
      dispatch(
        actions.warningsFetched({
          entities: response.data.notification,
          totalCount: response.data.total[0].total,
          notificationUnreadCountByType:
            response.data.notificationUnreadCountByType,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find links";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchNotifications = (pageNumber, pageSize) => async (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.notificationsList }));
  return requestFromServer
    .findNotifications({
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
      pageNumber: parseInt(pageNumber) - 1,
      pageSize: pageSize,
    })
    .then((response) => {
      dispatch(
        actions.notificationsFetched({
          entities: response.data.notification,
          totalCount: response.data.total[0].total,
          notificationUnreadCountByType:
            response.data.notificationUnreadCountByType,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find links";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLatestMovies = (pageNumber, pageSize) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.latestMoviesList }));
  return requestFromServer
    .findMovies({
      filter: {},
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
      pageNumber: parseInt(pageNumber) - 1,
      pageSize: pageSize,
    })
    .then((response) => {
      dispatch(actions.latestMoviesFetched({ entities: response.data.items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find latest movies";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLatestNews = (pageNumber, pageSize) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.latestNewsList }));
  return requestFromServer
    .findNews({
      filter: {},
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
      pageNumber: parseInt(pageNumber) - 1,
      pageSize: pageSize,
    })
    .then((response) => {
      dispatch(actions.latestNewsFetched({ entities: response.data.items }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find latest movies";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLanguage = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLanguage(id)
    .then((response) => {
      const language = response.data.toLowerCase();
      if (language !== getConfig().selectedLang) {
        setLanguage(language ?? "pt");
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find language";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getNotification = (id) => async (dispatch) => {
  return requestFromServer.getNotification(id);
};
