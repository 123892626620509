import React from "react";
import { Route, Switch } from "react-router-dom";
import { ErrorPage } from "./ErrorPage";

export default function ErrorsPage() {
  return (
    <Switch>
      <Route path="/error" component={ErrorPage} />
    </Switch>
  );
}
