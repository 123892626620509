import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "./Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { SnackbarProvider } from 'notistack';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../_metronic/_helpers";

export default function App({ store, persistor, basename }) {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      maxSnack={10}
      iconVariant={{
        error: <div className="svg-icon svg-icon-xl mr-2"><SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} className="svg-icon-default"/></div>,
        warning: <div className="svg-icon svg-icon-xl mr-2"><SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} className="svg-icon-secondary" /></div>,
    }}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          <Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
              <MaterialThemeProvider>
                <I18nProvider>
                  <Routes />
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  );
}
