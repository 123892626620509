import React, { Suspense, memo, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic/layout";

import { CanRoute } from "./Can";

import Home from "../pages/Home";
import { rolesConfig as roles } from "../utils/roles";

const AlertsPage = lazy(() => {
  return import("../modules/Alerts/Routes");
});

const ECommercePage = lazy(() => {
  return import("../modules/ECommerce/pages/eCommercePage");
});

const systemPage = lazy(() => {
  return import("../modules/System/systemRoutes");
});

const learningPage = lazy(() => {
  return import("../modules/Learning/learningRoutes");
});

const KnowledgeBase = lazy(() => {
  return import("../modules/KnowledgeBase/Routes");
});

const UserProfile = lazy(() => {
  return import("../modules/UserProfile/Routes");
});

function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/home" />

        <Route path="/user-profile" component={UserProfile} />

        <CanRoute perform={roles.HOME.INDEX} path="/home" component={Home} />

        <CanRoute
          perform={roles.LEARNING.INDEX}
          path="/alerts"
          component={AlertsPage}
        />

        <CanRoute
          perform={roles.SYSTEM.INDEX}
          path="/system"
          component={systemPage}
        />

        <CanRoute
          perform={roles.KNOWLEDGE.INDEX}
          path="/knowledge-base"
          component={KnowledgeBase}
        />

        <CanRoute
          perform={roles.LEARNING.INDEX}
          path="/learning"
          component={learningPage}
        />

        {/* -------------------------------------------------------------------------------- */}
        <CanRoute
          perform={roles.ECOMMERCE.INDEX}
          path="/e-commerce"
          component={ECommercePage}
        />
        {/* -------------------------------------------------------------------------------- */}

        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}

export default memo(BasePage);
