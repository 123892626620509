import api from "../../../services/api";

const REQUESTS_URL = "/user/find/1";
const APPROVAL_REQUEST_URL = "/users/changestate";
const STATISTICS_URL = "/dashboard";
const ROLES_URL = "/role";
const PRODUCTS_URL = "/products";
const BOOKMARK_URL = "/bookmark";

export const postBookmark = (requestBody) => {
  return api.post(`${BOOKMARK_URL}`, requestBody);
};
export const deleteBookmark = (id) => {
  return api.delete(`${BOOKMARK_URL}/${id}`);
};

export function getBookmarksContent(queryParams) {
  return api.post(`${BOOKMARK_URL}/contents/find`, queryParams);
}

export function getBookmarksByProducts(queryParams) {
  return api.post(
    `${STATISTICS_URL}/knowledge/bookmarks/products`,
    queryParams
  );
}
export function getHomeStatistics() {
  return api.post(STATISTICS_URL, {
    showLastUserViews: 5,
    showTotalViews: 5,
    showBookmarksByProducts: 5,
  });
}

export function getPendingRequests() {
  return api.post(REQUESTS_URL, {
    pageNumber: 0,
    pageSize: 10,
    searchText: "",
    sortField: "id",
    sortOrder: "ASC",
  });
}

export function getUserProducts() {
  return api.post(`/products/subproduct/find`, {
    pageNumber: 0,
    pageSize: 1000,
    searchText: "",
    sortField: "id",
    sortOrder: "ASC",
  });
}
export function getAllProducts() {
  return api.get(PRODUCTS_URL);
}

export function getAllRoles() {
  return api.get(ROLES_URL);
}

export function approvalRequest(userId, requestBody) {
  return api.post(`${APPROVAL_REQUEST_URL}/${userId}`, requestBody);
}

export function denyRequest(userId, requestBody) {
  return api.post(`${APPROVAL_REQUEST_URL}/${userId}`, requestBody);
}

export function receivedNotifications(notificationsIds) {
  return api.post(`/notification/received`, {
    notifications: notificationsIds,
  });
}

export function findWarnings(requestBody) {
  return api.post(`/notification/find/WARNING`, requestBody);
}

export function findNotifications(requestBody) {
  return api.post(`/notification/find/NOTIFICATION`, requestBody);
}

export function getNotification(id) {
  return api.patch(`/notification/read/${id}`);
}

export function findMovies(requestBody) {
  return api.post(`/knowledge/find/type/2`, requestBody);
}

export function findNews(requestBody) {
  return api.post(`/knowledge/find/type/4`, requestBody);
}
export function getLanguage(id) {
  return api.post(`/user/findLanguage/${id}`);
}
