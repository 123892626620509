import api from "../../../services/api";

export const LOGIN_URL = "/session";
export const REGISTER_URL = "/users/register";
export const REQUEST_PASSWORD_URL = "/users/changepassword";
export const APPEARANCE_URL = "/settings/appearance";

export function login(email, password) {
  return api.post(LOGIN_URL, { email, password });
}

export function register(email, name, language) {
  return api.post(REGISTER_URL, { email, name, language });
}

// export function requestPassword(email) {
//   return api.post(REQUEST_PASSWORD_URL, { email });
// }

export function requestPassword(email) {
  return api.post("/users/forgot-password", { email });
}

export function renewPassword(requestBody) {
  return api.post(
    REQUEST_PASSWORD_URL,
    { ...requestBody },
    { headers: { Authorization: `Bearer ${requestBody.token}` } }
  );
}

export function getSystemAppearance() {
  return api.get(APPEARANCE_URL);
}
