import { createSlice } from "@reduxjs/toolkit";

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  userForEdit: null,
  lastError: null,
  products: [],
  roles: [],
  status: [],
  rolesAllProducts: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    userFetched: (state, action) => {
      const { user } = action.payload;
      if (user !== undefined && user !== null) {
        if (!!user.role) {
          user.role = { value: user.role.id, label: user.role.name };
        } else {
          user.role = undefined;
        }

        user.sap = user.identifier;

        user.products = user?.userproduct?.map((product) => ({
          value: product?.system_product_id?.id,
          label: product?.system_product_id?.name,
        }));
      }

      state.userForEdit = {
        ...user,
        language: user?.language === null ? "pt" : user?.language,
      };
      state.error = null;
      state.actionsLoading = false;
    },
    usersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    userCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    userUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    userDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    productsFetched: (state, action) => {
      const { products } = action.payload;
      state.products = products.map((product) => ({
        value: product.id,
        label: product.name,
      }));
      state.error = null;
      state.listLoading = false;
    },
    rolesFetched: (state, action) => {
      const { roles } = action.payload;
      state.roles = roles.map((role) => ({ value: role.id, label: role.name }));
      state.error = null;
      state.listLoading = false;
    },
    statusFetched: (state, action) => {
      const { status } = action.payload;
      state.status = status.map((stat) => ({
        value: stat.id,
        label: stat.name,
      }));
      state.error = null;
      state.listLoading = false;
    },
    userBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    userUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    userPasswordReseted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    usersImported: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
