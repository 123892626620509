import { createSlice } from "@reduxjs/toolkit";

const initialBannerState = {
  listLoading: false,
  actionsLoading: false,
  banners: [],
  publics: [],
  bannerToEdit: null,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const bannersSlice = createSlice({
  name: "banner",
  initialState: initialBannerState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.bannerToEdit = null;
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    bannersFetched: (state, action) => {
      state.listLoading = false;
      state.banners = action.payload.banners;
      state.error = null;
    },

    getPublicBanners: (state, action) => {
      state.listLoading = false;
      state.publics = action.payload.banners;
      state.error = null;
    },

    getBanner: (state, action) => {
      state.listLoading = false;
      state.bannerToEdit = action.payload.banner;
      state.error = null;
    },

    bannerCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    bannerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    bannerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    changeVisibility: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    reorder: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    remove: (state, action) => {
      const id = action.payload;
      state.publics = state.publics.filter((banner) => banner.id !== id);
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
