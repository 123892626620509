import React, { useEffect } from "react";
import {
  Redirect,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout/index";
import BasePage from "./routes/BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/Error/ErrorsPage";
import Accomplishments from "./pages/Accomplishments";

export function Routes() {
  const history = useHistory();
  let location = useLocation();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isAuthorized && location.search && location.search.includes('callBackUrl')) {
      history.push(location.search.replace('?callBackUrl=', ''));
    }
  }, [window.location, isAuthorized]);

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <>
          <Route
            path="/accomplishments/verify/:certificate_code"
            component={Accomplishments}
          />
          <Route component={AuthPage} />
        </>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route
        path="/accomplishments/verify/:certificate_code"
        component={Accomplishments}
      />
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <>
          <Redirect from="/auth" to="/auth/login" />
        </>
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
      <Redirect to="/error" />
    </Switch>
  );
}
