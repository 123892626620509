import React, { useState } from 'react';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import * as actions from "../../_redux/home/homeActions";

import ApproveAccessModal from '../ApproveAccessModal';

const RequestsCards = ({ className, availableRequests, intl }) => {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [requestedUser, setRequestedUser] = useState(null);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.home }),
    shallowEqual
  );
  const { roles, products, rolePermissions } = currentState;

  const handleApproveAccess = (user) => {
    setRequestedUser(user);
    setShowApproveModal(true);
  };

  const dispatch = useDispatch();
  const handleDenyAccess = (user) => {
    Swal.fire({
      title: intl.formatMessage({ id: "LEARNING.MANAGEMENT.COURSE.TITLE_DELETE"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({id: "OPTION.YES"}),
      cancelButtonText: intl.formatMessage({id: "OPTION.NO"}),
    }).then((result) => {
      if (result.isConfirmed) {
        const requestBody = {
          status: 4
        };

        dispatch(actions.approveUser(user.id, requestBody))
          .then(() => {
              dispatch(actions.fetchAvailableRequests());
          })
          .catch((error) => {
              console.log(error);
          });
      }
    });
  };

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              <FormattedMessage id="HOME.REQUESTS" />
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{minWidth: "250px"}}>
                    <span className="text-dark-75"></span>
                  </th>
                  <th style={{minWidth: "80px"}}/>
                </tr>
                </thead>
                <tbody>
                  {availableRequests.map((user, index) => (
                    <tr key={user.id}>
                      <td className="pl-0 py-2">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-light mr-4">
                              <span className="symbol-label" style={{backgroundImage: `url(${toAbsoluteUrl("media/users/default.jpg")})`}}>
                              </span>
                          </div>
                          <div>
                            <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                              {user.name}
                            </span>
                            <span className="text-muted font-weight-bold d-block">
                              {user.email}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="pr-0 text-right">
                        <OverlayTrigger overlay={
                          <Tooltip id="content-edit-tooltip">
                            <FormattedMessage id="HOME.REQUESTS.APPROVE" />
                          </Tooltip>}
                        >
                          <a
                            onClick={() => { handleApproveAccess(user); }}
                            className="btn btn-icon btn-secondary btn-hover-success m-1">
                            <span className="svg-icon svg-icon-lg svg-icon-success">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-check.svg")} />
                            </span>
                          </a>
                        </OverlayTrigger>
    
                        <OverlayTrigger overlay={
                          <Tooltip id="content-edit-tooltip">
                              <FormattedMessage id="HOME.REQUESTS.DENY" />
                          </Tooltip>}
                        >
                          <a
                            onClick={() => { handleDenyAccess(user); }}
                            className="btn btn-icon btn-secondary btn-hover-danger m-1">
                            <span className="svg-icon svg-icon-lg svg-icon-danger">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-disabled.svg")} />
                            </span>
                          </a>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ApproveAccessModal
        show={showApproveModal}
        requestedUser={requestedUser}
        roles={roles}
        products={products}
        rolePermissions={rolePermissions}
        onHide={() => { setShowApproveModal(false); }}
      />
    </>
  );
};

export default injectIntl(RequestsCards);