import React, { useEffect, useState } from "react";
import { Modal, Alert } from "react-bootstrap";
import { Input, ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Form, Field } from "formik";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import Select from "react-select";
import { useDispatch } from "react-redux";
import * as actions from "../../_redux/home/homeActions";

import { ApprovalValidationSchema } from './validators/approvalValidator';
import { languages } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";

const ApproveAccessModal = ({ requestedUser, onHide, show, isLoading, intl, roles, products, rolePermissions }) => {
    const [blockProducts, setBlockProducts] = useState(false);

    useEffect(() => {
      setBlockProducts(false);
    }, [show]);

    const dispatch = useDispatch();
    const handleSaveApprovment = (formFields) => {
        const requestBody = {
            status: 3,
            role: formFields.role_id.value,
        };

        if(formFields.products.length > 0) {
            requestBody.products = formFields.products;
        }

        if(formFields.sap !== "") {
            requestBody.identifier = formFields.sap;
        }

        dispatch(actions.approveUser(requestedUser.id, requestBody))
            .then(() => {
                onHide();
                dispatch(actions.fetchAvailableRequests());
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const checkRolePermission = (roleId) => {
        const role = rolePermissions.find((role) => (role.id === roleId));
        if(role) {
            const systemProductsGetAll = role.permissions.find((permission) => (permission.name === "system-products:get-all"));
            if(systemProductsGetAll) {
                setBlockProducts(true);
            } else {
                setBlockProducts(false);
            }
        } else {
            setBlockProducts(false);
        }
    };

    
    const getLanguage = () => {
        return languages.find(
            (language) => language.lang === (requestedUser?.language?.toLowerCase() ?? "pt")
            );
        }
        

    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && <ModalProgressBar />}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Aprovação</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            role_id: requestedUser?.role ?
              {
                label: requestedUser?.role?.name,
                value: requestedUser?.role?.id,
              } : undefined,
            products: [],
            sap: "",
          }}
          validationSchema={ApprovalValidationSchema(intl, blockProducts)}
          enableReinitialize={true}
          onSubmit={(formFields) => {
            handleSaveApprovment(formFields);
          }}
        >
          {({ errors, touched, setFieldValue, handleSubmit, values }) => (
            <>
              <Modal.Body>
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <div className="symbol symbol-50 symbol-light mr-4">
                              <span
                                className="symbol-label"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(
                                    "media/users/default.jpg"
                                  )})`,
                                }}
                              ></span>
                            </div>
                            <div>
                              <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                {requestedUser.name}
                              </a>
                              <span className="text-muted font-weight-bold d-block">
                                {requestedUser.email}
                              </span>
                            </div>
                          </div>
                          <span className="text-muted font-weight-bold d-flex align-items-center">
                            {getLanguage()?.name}
                            <img
                              className="h-25px w-25px rounded ml-2"
                              src={`${getLanguage()?.flag}`}
                              alt={`${getLanguage()?.flag}`}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="sap" className="pt-3">
                          <FormattedMessage id="SYSTEM.USERS.MODAL_SAP" />
                        </label>
                        <Field
                          name="sap"
                          component={Input}
                          placeholder={intl.formatMessage({
                            id: "PLACEHOLDER.USERS.SAP",
                          })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="profile" className="pt-3">
                          <FormattedMessage id="SYSTEM.USERS.MODAL_PROFILE" />
                        </label>
                        <div className="form-group m-0">
                          <Field
                            options={roles}
                            name="role_id"
                            value={values.role_id}
                            component={Select}
                            onChange={(e) => {
                              setFieldValue("role_id", e);
                              checkRolePermission(parseInt(e.value));
                            }}
                          />
                          {errors.role_id && touched.role_id ? (
                            <code>{errors.role_id}</code>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-gorup">
                        {blockProducts ? (
                          <Alert variant="warning">
                            {intl.formatMessage({
                              id: "HOME.REQUESTS.MESSAGE.ROLE.ALL_PRODUCTS",
                            })}
                          </Alert>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="products" className="pt-3">
                          <FormattedMessage id="SYSTEM.USERS.MODAL_PRODUCTS" />
                        </label>
                        <div className="form-group m-0">
                          <Field
                            isDisabled={blockProducts}
                            options={products}
                            menuPlacement="auto"
                            component={Select}
                            isMulti
                            closeMenuOnSelect={false}
                            name="products"
                            placeholder={intl.formatMessage({
                              id: "PLACEHOLDER.USERS.PRODUCTS",
                            })}
                            onChange={(e) => {
                              setFieldValue(
                                "products",
                                e?.map((e) => e.value)
                              );
                            }}
                          />
                          {errors.products && touched.products ? (
                            <code>{errors.products}</code>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate mr-4"
                  >
                    <FormattedMessage id="SYSTEM.USERS.DELETE_DIALOG.CANCEL" />
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-success btn-elevate"
                  >
                    Aprovar
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    );
};

export default injectIntl(ApproveAccessModal);